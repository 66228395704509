import React, { useEffect, useContext } from "react"
import useWindowWidth from "react-hook-use-window-width"

import { SnackbarContext } from "../../app"

import WelcomeHeader from "../../welcome-header/welcome-header"
import WelcomeCarouselDesktop from "../../carousels/welcome-carousel-desktop"
import WelcomeCarouselMobile from "../../carousels/welcome-carousel-mobile"

import Signup from "../../signup/signup"
import Snackbar from "../../snackbar/snackbar"

function Welcome() {
  const width = useWindowWidth()
  const snackbarContext = useContext(SnackbarContext)

  // function scrollFun() {
  //   document.documentElement.scrollIntoView()
  // }

  useEffect(() => {
    let height = 900
    if (document.querySelector(".welcome-screen")) {
      setTimeout(() => {
        if (height !== document.getElementById("iframe-height").offsetHeight) {
          height = document.getElementById("iframe-height").offsetHeight
          window.parent.postMessage(
            {
              frameHeight: Number(`${height === 0 ? 900 : height}`),
              // scrollFunction: scrollFun(),
            },
            "*"
          )
        }
      }, 1000)
    }
  }, [])

  return (
    <>
      <section className="chart-container chart-container__bg position-relative">
        {snackbarContext.state.content !== "" && (
          <div className="welcome-snackbar">
            <Snackbar />
          </div>
        )}

        <WelcomeHeader />
        <main className="d-lg-flex justify-content-lg-between welcome-screen welcome-screen__bg">
          {width >= 992 ? (
            <>
              <WelcomeCarouselDesktop />
              <div className="welcome-screen__signup">
                <Signup />
              </div>
            </>
          ) : (
            <WelcomeCarouselMobile />
          )}
        </main>
      </section>
    </>
  )
}

export default Welcome
