import React, { useState } from 'react';

import SearchField from "../../search-field/search-field";
import ProfileDropdown from "../../profile-dropdown/profile-dropdown";
import PuppyListCarousel from "../../carousels/puppy-list-carousel";
import DataVisualization from "../../data-visualization/data-visualization";

function HomepageDesktop({ searchPets, setSearchPets }) {
 
  return (
    <>
      <section className="chart-container chart-container__bg">
        <header className="d-flex justify-content-between align-items-center chart-container__login-header">
          <div className="title-container">
            <h5 className="text-mars-blue mb-0">My Pets</h5>
            <p className="body-2 text-mars-blue mb-0">
              Keep your pet's growth on track by regularly monitoring their
              weight.
            </p>
          </div>
         
          <SearchField searchPets={searchPets} setSearchPets={setSearchPets}/>
        
          <ProfileDropdown />
        </header>

        <PuppyListCarousel searchPets={searchPets} />

        {/* <div id="puppyDataViz" className="puppy-data-viz"></div> */}
        <DataVisualization />
      </section>
    </>
  );
}

export default HomepageDesktop;
