import React, { useState, useContext } from "react"
import Modal from "react-bootstrap/Modal"
import moment from "moment"
import axios from "axios"

import AddMyPet from "../forms/form-signup/form-add-my-pet"
import UserProfile from "../forms/form-signup/form-user-profile"
import OTPBox from "../forms/otp-box/otp-box"

import {
  LoadingContext,
  IsLoginContext,
  IsNewUserRequestContext,
  OTPRequestContext,
  ModalContext,
  LoginProfileDataContext,
  SnackbarContext,
} from "../app"

const initialValues = {
  petName: "",
  birthDate: "",
  knowPetsBreed: "",
  petsBreed: "",
  mixedBreed: false,
  petsAdultWeight: "",
  petsGender: "",
  firstName: "",
  lastName: "",
  emailAddress: "",
  password: "",
  confirmPassword: "",
  weightUnitPet: "kg",
  species: "dog",
}

function ModalSignup(props) {
  const [data, setData] = useState(initialValues)
  const [currentStep, setCurrentStep] = useState(0)
  // const [dobError, setDobError] = useState("")
  const [OTPError, setOTPError] = useState("")

  const loadingContext = useContext(LoadingContext)
  const isLogin = useContext(IsLoginContext)
  const modalContext = useContext(ModalContext)
  const isNewUserRequestContext = useContext(IsNewUserRequestContext)
  const otpRequestContext = useContext(OTPRequestContext)
  const loginProfileDataContext = useContext(LoginProfileDataContext)
  const snackbarContext = useContext(SnackbarContext)

  const makeIsNewUserRequest = formData => {
    loadingContext.setLoading(true)
    const dataToSend = {
      email: formData.emailAddress,
      password: formData.password,
    }

    var config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/is-new-user`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)
        if (
          response.data.error === false &&
          response.data.message === "User Verified but not submitted"
        ) {
          isNewUserRequestContext.dispatch({
            type: "USER_VERIFIED_NOT_SUBMITTED",
            message: "",
          })
          setCurrentStep(prev => prev + 1)
        } else if (
          response.data.error === false &&
          response.data.message === "new user"
        ) {
          makeRegisterOwnerRequest(formData)
        } else if (
          response.data.error === true &&
          response.data.message ===
            "Account already exists with this mail address. Kindly login!"
        ) {
          isNewUserRequestContext.dispatch({
            type: "USER_EXISTS",
            message: response.data.message,
          })
        }
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
      })
  }

  const makeRegisterOwnerRequest = formData => {
    loadingContext.setLoading(true)
    const dataToSend = {
      email: formData.emailAddress,
      password: formData.password,
      first_name: formData.firstName,
      last_name: formData.lastName,
    }

    var config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/register-owner`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)
        if (response.data.failedPolicies) {
          otpRequestContext.dispatch({
            type: "PASSWORD_FAILED",
            passwordErrorText: response.data.failedPolicies.password.message,
          })
        } else {
          otpRequestContext.dispatch({
            type: "OTP_SENT_SUCCESS",
            authId: response.data.authId,
            ownerEnterCookie: response.data.owner_enter_cookie,
          })
          setCurrentStep(prev => prev + 1)
        }
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
      })
  }

  const makeOwnerOtpVerifyRequest = (
    OTPValue,
    setOTPMatched,
    setOTPNotMatched,
    setOTPResendEnable,
    setOTPTimerStart,
    setOTPTimerDuration
  ) => {
    loadingContext.setLoading(true)
    const dataToSend = {
      authId: otpRequestContext.state.authId,
      owner_enter_cookie: otpRequestContext.state.ownerEnterCookie,
      otp: OTPValue,
    }

    var config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/register-owner-otp-verify`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)
        if (response.data.tokenId) {
          setOTPMatched(true)
          setOTPNotMatched(false)
          setOTPResendEnable(false)
          setOTPTimerStart(false)
          setOTPTimerDuration(30)
          makeSignupRequest()
        } else {
          setOTPNotMatched(true)
        }
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
      })
  }

  const makeSignupRequest = () => {
    loadingContext.setLoading(true)

    let filteredBreed
    if(data.species === "dog") {
      if(data.mixedBreed && data.petsBreed !== "") {
        filteredBreed = data.petsBreed
      } else if(data.mixedBreed && data.petsBreed === "") {
        filteredBreed = null
      } else if(!data.mixedBreed && data.petsBreed !== "") {
        filteredBreed = data.petsBreed
      }
    } else if(data.species === "cat") {
      if(data.mixedBreed) {
        filteredBreed = null
      } else {
        filteredBreed = data.petsBreed
      }
    }

    const dataToSend = {
      owner: {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.emailAddress,
        password: data.password,
        weight_unit: data.weightUnitPet,
      },
      pet: {
        pet_name: data.petName,
        dob: moment(data.birthDate).format("YYYY-MM-DD"),
        do_you_know_your_pets_breed: data.knowPetsBreed,
        breed: filteredBreed,
        mixed_breed: data.mixedBreed === true ? "yes" : null,
        breed_size: data.petsAdultWeight,
        sex: data.petsGender,
        neuter_date: null,
        species: data.species, //cat or dog allowed
      },
    }
    var config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/signup`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)
        if (response.data.owner) {
          setCurrentStep(0)
          loginProfileDataContext.setState(response.data)
          modalContext.dispatch("hideSignupModal")
          isLogin.setIsLogin(true)
          localStorage.setItem("token", response.data.owner.token)
          snackbarContext.setState({
            classes: "alert-success icon-success",
            content: "Welcome, your account was created successfully.",
          })
        }
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
      })
  }

  const handleNextStep = (newData, ownerData = false, otpVerify = false) => {
    setData(prev => ({ ...prev, ...newData }))
    if (ownerData) {
      makeIsNewUserRequest(newData)
    } else if (otpVerify) {
      makeSignupRequest(newData)
      return
    } else {
      setCurrentStep(prev => prev + 1)
    }
  }

  const handlePrevStep = newData => {
    setData(prev => ({ ...prev, ...newData }))
    setCurrentStep(prev => prev - 1)
  }

  const steps = [
    <AddMyPet
      next={handleNextStep}
      data={data}
      resetValues={initialValues}
      // dobError={dobError}
    />,
    <UserProfile next={handleNextStep} data={data} setData={setData} />,
    <OTPBox
      next={handleNextStep}
      data={data}
      signupForm={true}
      emailAddress={data.emailAddress}
      password={data.password}
      title={
        "To complete the registration process, we need to verify your email."
      }
      OTPError={OTPError}
      setOTPError={setOTPError}
      makeOwnerOtpVerifyRequest={makeOwnerOtpVerifyRequest}
    />,
  ]

  return (
    <>
      <Modal
        {...props}
        size="lg"
        centered
        animation
        scrollable
        onExited={() => {
          setData(initialValues)
          isNewUserRequestContext.state.userAlreadyExists = ""
          setCurrentStep(0)
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h6 className="modal-title text-mars-blue">
              {currentStep > 0 ? "User profile" : "Add My Pet"}
            </h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{steps[currentStep]}</Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          {currentStep === 0 ? (
            <button
              type="button"
              className="btn btn-outline-mars-blue"
              onClick={() => modalContext.dispatch("hideSignupModal")}
            >
              Close
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-outline-mars-blue"
              onClick={() => handlePrevStep(data)}
            >
              Back
            </button>
          )}
          <button type="submit" className="btn btn-mars-blue" form="myform">
            {currentStep !== steps.length - 1 ? "Next" : "Submit"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalSignup
