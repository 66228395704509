import React, { useContext } from "react"

import {
  UndoContext,
  ToggleGrowthGuidanceSnackbarContext,
} from "../../templates/homepage/homepage"

function BtnActions({ showActionOptions, setShowActionOptions }) {
  const undoContext = useContext(UndoContext)
  const toggleGrowthGuidanceSnackbarContext = useContext(
    ToggleGrowthGuidanceSnackbarContext
  )

  return (
    <>
      <div
        className={`btn-actions cursor-pointer ${
          undoContext.state && "disabled"
        }`}
      >
        <figure
          className="mb-0 d-none d-lg-block"
          onClick={() => {
            toggleGrowthGuidanceSnackbarContext.setState(true)
            setShowActionOptions(!showActionOptions)
          }}
        >
          <img
            className="img-fluid"
            src="../../icons/icon-add-btn.png"
            alt=""
          />
        </figure>
        <div className="d-grid d-lg-none">
          <button
            className="btn btn-outline-mars-blue"
            onClick={() => {
              toggleGrowthGuidanceSnackbarContext.setState(true)
              setShowActionOptions(!showActionOptions)
            }}
          >
            <img src="../../icons/icon-plus.png" alt="" />
            Actions
          </button>
        </div>
      </div>
    </>
  )
}

export default BtnActions
