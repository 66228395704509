import React from "react"

import ScaleGrowthChart from "./scale-growth-chart"

function ScaleChart({
  setShowScaleChart,
  data,
  percentiles,
  setUpdateAddedPetWeight,
  setWeightInitialValues,
  setShowModalWeight,
  setWeightShallowInitialValues,
  weightUnitLocal,
}) {
  return (
    <>
      <div className="scale-chart-backdrop"></div>
      <div className="scale-chart-wrapper">
        <div className="scale-chart-inner d-flex justify-content-center align-items-center">
          <div className="scale-chart-hldr position-relative">
            <div
              className="scale-chart-close position-absolute"
              onClick={() => setShowScaleChart(false)}
            >
              <figure className="mb-0">
                <img
                  className="img-fluid"
                  src="../../../icons/icon-close-chart.png"
                  alt=""
                />
              </figure>
            </div>

            <ScaleGrowthChart
              setShowScaleChart={setShowScaleChart}
              data={data}
              percentiles={percentiles}
              setUpdateAddedPetWeight={setUpdateAddedPetWeight}
              setWeightInitialValues={setWeightInitialValues}
              setShowModalWeight={setShowModalWeight}
              setWeightShallowInitialValues={setWeightShallowInitialValues}
              weightUnitLocal={weightUnitLocal}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ScaleChart
