import React, { useEffect, useContext, useRef, useState } from "react"
import DatePicker from "react-datepicker"
import Select from "react-select"
import { isEqual } from "lodash"

import { Formik, Form, Field } from "formik"
import { ConnectedFocusError } from "focus-formik-error"
import * as Yup from "yup"

/***** Context *****/
import { PetBreedsContext, ModalContext, SnackbarContext, IsLoginContext, LoginProfileDataContext } from "../../app"

/***** JSONS - Pets Adult Weight *****/
import petsAdultWeightOptions from "../../../jsons/pets_adult_weight.json"
import catBreedOptions from "../../../jsons/cat_breeds.json"
import { CurrentPetDataContext } from "../../templates/homepage/homepage"

function AddMyPet(props) {
  const petBreedOptions = useContext(PetBreedsContext)

  const modalContext = useContext(ModalContext)
  const snackbarContext = useContext(SnackbarContext)
  const isLoginContext = useContext(IsLoginContext)
  const currentPetDataContext = useContext(CurrentPetDataContext)
  const dobRef = useRef(null)
  const petsBreedRef = useRef()
 
  const [firstWeightMeasurementDate, setFirstWeightMeasurementDate] = useState(null);
  // console.log(firstWeightMeasurementDate)

  const validationSchema = Yup.object({
    species: Yup.string().required("Required"),
    petName: Yup.string().required("Required"),
    birthDate:
      props.updatePetsProfile && firstWeightMeasurementDate !== null
        ? Yup.date()
            .min("01/01/1990")
            .max(new Date(firstWeightMeasurementDate))
            .required("Required")
            .nullable()
        : Yup.date()
            .min("01/01/1990")
            .max(new Date())
            .required("Required")
            .nullable(),
    petsBreed: Yup.string().when(["mixedBreed"], {
      is: (val) => val === false,
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired(),
    }),
    // petsBreed: Yup.string().when("species", {
    //   is: "puppy",
    //   then: Yup.string().required("Required"),
    //   otherwise: Yup.string().notRequired(),
    // }),
    petsAdultWeight: Yup.string().when("species", {
      is: "dog",
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired(),
    }),
    petsGender: Yup.string().required("Required"),
    weightUnitPet: Yup.string().required("Required"),
  });

  const { dog, cat } = petBreedOptions
  let dogBreedOptions = dog,
    catBreedOptions = cat

  let dogBreedOptionsObject = [],
    catBreedOptionsObject = []

  dogBreedOptions.map(dogBreed => {
    dogBreedOptionsObject.push({ value: dogBreed, label: dogBreed })
  })
  dogBreedOptionsObject.push({ value: "Other", label: "Other" })

  catBreedOptions.map(catBreed => {
    catBreedOptionsObject.push({ value: catBreed, label: catBreed })
  })

  let filteredPetsAdultWeightObject
  if (props.data.weightUnitPet === "kg") {
    filteredPetsAdultWeightObject = petsAdultWeightOptions.kg.filter(
      option => option.value === props.data.petsAdultWeight
    )
  } else if (props.data.weightUnitPet === "lbs") {
    filteredPetsAdultWeightObject = petsAdultWeightOptions.lbs.filter(
      option => option.value === props.data.petsAdultWeight
    )
  }

  let filteredPetsAdultWeightOption
  if (filteredPetsAdultWeightObject.length !== 0) {
    filteredPetsAdultWeightOption = filteredPetsAdultWeightObject[0].label
  }

  useEffect(() => {
    if (props?.updatePetsProfile) {
      currentPetDataContext?.state?.weights?.weights[0]?.date !== undefined &&
        setFirstWeightMeasurementDate(
          currentPetDataContext?.state?.weights?.weights[0]?.date
        );
      // if (
      //   currentPetDataContext?.state?.weights?.weights[0]?.date !== undefined
      // ) {
      //   let previousDate = new Date(
      //     currentPetDataContext?.state?.weights?.weights[0]?.date
      //   );
      //   console.log(previousDate);
      //   previousDate.setDate(previousDate.getDate() - 1);
      //   setFirstWeightMeasurementDate(previousDate);
      // }
    }
  }, [props?.updatePetsProfile]);

  const handleSubmit = values => {
    if (values.species === "kitten") {
      values.petsAdultWeight = "";
      values.petsBreed = "";
    }
    if (props.updatePetsProfile) {
      if (isEqual(values, props.data)) {
        modalContext.dispatch("hideUpdatePetsProfileModal");
        snackbarContext.setState({
          classes: "alert-danger icon-failed",
          content: "There are no changes to submit",
        });
      } else {
        props.makeUpdatePetRequest(values);
      }
    } else if (props.addNewPuppy) {
      props.makeAddPuppyRequest(values, dobRef);
    } else {
      props.next(values, false, false);
    }
  }

  return (
    <>
      {!props.updatePetsProfile && (
        <>
          <p className="caption text-mars-blue">
            There's some important information we need to know about your pet so
            we can accurately monitor their growth.
          </p>
        </>
      )}

      <p className="caption text-mars-blue">
        All fields marked with an asterisk (
        <span className="text-mars-red">*</span>) are required
      </p>

      <Formik
        initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {formik => {
          if (props.setData) {
            props.setData(formik.values)
          }
          return (
            <>
              <Form id="myform" autoComplete="off">
                <ConnectedFocusError />
                <div
                  className={`form-input-hldr ${
                    formik.touched.species && formik.errors.species
                      ? "input-error"
                      : null
                  }`}
                >
                  <p className="form-label">Select Pet*</p>
                  <div className="btn-group">
                    <Field
                      id="dog"
                      className="btn-check"
                      type="radio"
                      name="species"
                      value="dog"
                      disabled={props.updatePetsProfile}
                      onClick={() => {
                        if (formik.values.species === "cat") {
                          formik.setValues(props.resetValues);
                          formik.setTouched({});
                        }
                      }}
                    />
                    <label className="btn" htmlFor="dog">
                      Puppy
                    </label>

                    <Field
                      id="cat"
                      className="btn-check"
                      type="radio"
                      name="species"
                      value="cat"
                      disabled={props.updatePetsProfile}
                      onClick={() => {
                        if (formik.values.species === "dog") {
                          formik.setValues(props.resetValues);
                          formik.setTouched({});
                        }
                      }}
                    />
                    <label className="btn" htmlFor="cat">
                      Kitten
                    </label>
                  </div>
                </div>
                {formik.values.species === "dog" && (
                  <>
                    {!props.updatePetsProfile && (
                      <>
                        <p className="caption text-mars-blue">
                          Did you know different sizes of dogs grow at varying
                          rates and reach adult size at different ages, this
                          means that you will need to select a growth chart that
                          matches their expected adult weight. Don't worry if
                          you choose the wrong one, you can change the chart in
                          your pet's profile later on.
                        </p>
                      </>
                    )}
                  </>
                )}

                <div
                  className={`form-input-hldr ${
                    formik.touched.petName && formik.errors.petName
                      ? "input-error"
                      : null
                  }`}
                >
                  <label htmlFor="petName" className="form-label">
                    Pet name*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="petName"
                    name="petName"
                    placeholder="Your Pet's name"
                    value={formik.values.petName}
                    onChange={formik.handleChange}
                  />
                </div>
                <div
                  className={`form-input-hldr ${
                    formik.touched.birthDate && formik.errors.birthDate
                      ? "input-error"
                      : null
                  } ${
                    (props.addNewPuppy || props.updatePetsProfile) &&
                    formik.errors.birthDate &&
                    formik.touched.birthDate
                      ? "input-error"
                      : ""
                  }`}
                >
                  <label
                    htmlFor="birthDate"
                    className="form-label"
                    ref={dobRef}
                  >
                    Date of birth*{" "}
                  </label>
                  <Field name="birthDate">
                    {({ form, field }) => {
                      const { setFieldValue, setFieldTouched } = form;
                      const { value } = field;

                      return (
                        <DatePicker
                          id="birthDate"
                          className="form-control"
                          autoComplete="off"
                          dateFormat="dd/MM/yyyy"
                          {...field}
                          selected={value}
                          calendarStartDay={1}
                          maxDate={new Date()}
                          placeholderText="DD/MM/YYYY"
                          onChange={(val, e) => {
                            setFieldValue("birthDate", val);
                          }}
                          popperClassName="app-datepicker"
                          popperPlacement="bottom-end"
                          formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                        />
                      );
                    }}
                  </Field>
                  {props.updatePetsProfile &&
                  (currentPetDataContext?.state?.weights?.weights).length > 0 &&
                  new Date(formik.values.birthDate) >=
                    new Date(firstWeightMeasurementDate) &&
                  formik.errors.birthDate &&
                  formik.touched.birthDate ? (
                    <p className="caption text-mars-red">
                      Date must be before the first measurement date.
                    </p>
                  ) : formik.errors.birthDate && formik.touched.birthDate ? (
                    <p className="caption text-mars-red">
                      Date must be after 1990.
                    </p>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  className={`form-input-hldr ${
                    formik.touched.petsBreed && formik.errors.petsBreed
                      ? "input-error"
                      : null
                  }`}
                >
                  <label htmlFor="petsBreed" className="form-label">
                    Breed*{" "}
                  </label>
                  <div className="d-md-flex justify-content-md-between align-items-md-center">
                    <div className="flex-md-fill me-md-4">
                      <Field name="petsBreed">
                        {({ form, field }) => {
                          const { setFieldValue, touched } = form;
                          const { value } = field;
                          return (
                            <>
                              {formik.values.species === "dog" && (
                                <>
                                  <Select
                                    defaultValue={
                                      value
                                        ? {
                                            value: value,
                                            label: formik.values.petsBreed,
                                          }
                                        : false
                                    }
                                    name="petsBreed"
                                    options={dogBreedOptionsObject}
                                    onChange={(val) => {
                                      setFieldValue("petsBreed", val.value);
                                    }}
                                    touched={touched.petsBreed}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    // isDisabled={
                                    //   form.values.mixedBreed === true && true
                                    // }
                                    placeholder="Start typing your pet's breed"
                                  />
                                </>
                              )}
                              {formik.values.species === "cat" &&
                                !formik.values.mixedBreed && (
                                  <>
                                    <Select
                                      defaultValue={
                                        value
                                          ? {
                                              value: value,
                                              label: formik.values.petsBreed,
                                            }
                                          : false
                                      }
                                      name="petsBreed"
                                      options={catBreedOptionsObject}
                                      onChange={(val) => {
                                        setFieldValue("petsBreed", val.value);
                                      }}
                                      touched={touched.petsBreed}
                                      className="react-select-container"
                                      classNamePrefix="react-select"
                                      isDisabled={
                                        form.values.mixedBreed === true && true
                                      }
                                      placeholder="Start typing your pet's breed"
                                    />
                                  </>
                                )}
                              {formik.values.species === "cat" &&
                                formik.values.mixedBreed && (
                                  <>
                                    <Select
                                      name="petsBreed"
                                      options={catBreedOptionsObject}
                                      onChange={(val) => {
                                        setFieldValue("petsBreed", val.value);
                                      }}
                                      touched={touched.petsBreed}
                                      className="react-select-container"
                                      classNamePrefix="react-select"
                                      isDisabled={
                                        form.values.mixedBreed === true && true
                                      }
                                      placeholder="Start typing your pet's breed"
                                    />
                                  </>
                                )}
                            </>
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-check mb-0">
                      <Field
                        id="mixedBreed"
                        className="form-check-input"
                        type="checkbox"
                        name="mixedBreed"
                        // value="yes"
                      />

                      <label
                        className="form-check-label body-2"
                        htmlFor="mixedBreed"
                      >
                        {formik.values.species === "dog"
                          ? "Mixed breed"
                          : "I don't know"}
                      </label>
                    </div>
                  </div>
                </div>
                {formik.values.species === "dog" && (
                  <>
                    <div
                      className={`form-input-hldr ${
                        formik.touched.petsAdultWeight &&
                        formik.errors.petsAdultWeight
                          ? "input-error"
                          : null
                      }`}
                    >
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <label htmlFor="petsAdultWeight" className="form-label">
                          Expected adult weight*
                        </label>
                        <div className="secondary">
                          <div className="btn-group">
                            <Field
                              id="weightUnitPetKg"
                              className="btn-check"
                              type="radio"
                              name="weightUnitPet"
                              value="kg"
                              disabled={
                                props.updatePetsProfile || props.addNewPuppy
                              }
                              onClick={() =>
                                formik.setFieldValue("petsAdultWeight", "")
                              }
                            />
                            <label className="btn" htmlFor="weightUnitPetKg">
                              kg
                            </label>

                            <Field
                              id="weightUnitPetLbs"
                              className="btn-check"
                              type="radio"
                              name="weightUnitPet"
                              value="lbs"
                              disabled={
                                props.updatePetsProfile || props.addNewPuppy
                              }
                              onClick={() =>
                                formik.setFieldValue("petsAdultWeight", "")
                              }
                            />
                            <label className="btn" htmlFor="weightUnitPetLbs">
                              lbs
                            </label>
                          </div>
                        </div>
                      </div>

                      <Field name="petsAdultWeight">
                        {({ form, field }) => {
                          const {
                            setFieldValue,
                            handleChange,
                            handleBlur,
                            setFieldTouched,
                          } = form;
                          const { value } = field;
                          return (
                            <>
                              {formik.values.weightUnitPet === "kg" && (
                                <Select
                                  defaultValue={
                                    value
                                      ? {
                                          value: value,
                                          label: filteredPetsAdultWeightOption,
                                        }
                                      : false
                                  }
                                  name="petsAdultWeight"
                                  options={petsAdultWeightOptions.kg}
                                  onChange={(val) => {
                                    setFieldValue("petsAdultWeight", val.value);
                                  }}
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  placeholder="Expected adult weight"
                                />
                              )}

                              {formik.values.weightUnitPet === "lbs" && (
                                <Select
                                  defaultValue={
                                    value
                                      ? {
                                          value: value,
                                          label: filteredPetsAdultWeightOption,
                                        }
                                      : false
                                  }
                                  name="petsAdultWeight"
                                  options={petsAdultWeightOptions.lbs}
                                  // {...field}
                                  onChange={(val) => {
                                    setFieldValue("petsAdultWeight", val.value);
                                  }}
                                  // onBlur={() => {
                                  //   setFieldTouched("petsAdultWeight")
                                  // }}
                                  isSearchable={false}
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  placeholder="Expected adult weight"
                                />
                              )}
                            </>
                          );
                        }}
                      </Field>
                    </div>
                  </>
                )}

                {/* <div className="form-input-radio-hldr">
                  <p className="caption">Do you know your pet's breed?</p>
                  <div className="form-check">
                    <Field
                      id="petsBreedYes"
                      className="form-check-input"
                      type="radio"
                      name="knowPetsBreed"
                      value="yes"
                    />
                    <label
                      className="form-check-label body-2"
                      htmlFor="petsBreedYes"
                    >
                      Yes, I can specify my pet's breed
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      id="petsBreedNo"
                      className="form-check-input"
                      type="radio"
                      name="knowPetsBreed"
                      value="no"
                    />
                    <label
                      className="form-check-label body-2"
                      htmlFor="petsBreedNo"
                    >
                      No, I will specify only the pet's adult weight
                    </label>
                  </div>
                </div> */}
                {formik.values.species === "dog" && (
                  <>
                    <div className="advice">
                      <p className="caption mb-0">
                        You could ask your veterinarian or breeder for advice or
                        get a predicted weight and much more from{" "}
                        <a
                          href="https://www.wisdompanel.com/"
                          target="_blank"
                          className="text-mars-red"
                        >
                          WISDOM PANEL™
                        </a>{" "}
                        genetic tests
                      </p>
                    </div>
                  </>
                )}
                <div
                  className={`form-input-hldr ${
                    formik.touched.petsGender && formik.errors.petsGender
                      ? "input-error"
                      : null
                  }`}
                >
                  <p className="caption">Pet's sex*</p>
                  <div className="btn-group">
                    <Field
                      id="male"
                      className="btn-check"
                      type="radio"
                      name="petsGender"
                      value="Male"
                    />
                    <label className="btn" htmlFor="male">
                      Male
                    </label>

                    <Field
                      id="female"
                      className="btn-check"
                      type="radio"
                      name="petsGender"
                      value="Female"
                    />
                    <label className="btn" htmlFor="female">
                      Female
                    </label>
                  </div>
                </div>
                {props.updatePetsProfile && (
                  <div className="d-inline-flex align-items-center btn-text-icon">
                    <figure className="flex-shrink-0 mb-0">
                      <img
                        className="img-fluid"
                        src="../../../icons/icon-trash.svg"
                        alt=""
                      />
                    </figure>
                    <span
                      className="d-inline-block btn-text"
                      onClick={() =>
                        modalContext.dispatch("showRemovePetsProfileModal")
                      }
                    >
                      Remove profile
                    </span>
                  </div>
                )}
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  )
}

export default AddMyPet
