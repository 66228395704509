import React from "react"

function FormLoadingIndicator() {
  return (
    <>
      <div className="loading form-loading-indicator">
        <figure className="mb-0">
          <img
            className="img-fluid"
            src="../../../form-loading-indicator.png"
            alt=""
          />{" "}
        </figure>
        
      </div>
    </>
  )
}

export default FormLoadingIndicator
