import React, { useState, useEffect, useContext } from "react"

import {
  SkeletonLoadingContext,
  SnackbarContext,
  GrowthChartSnackbarContext,
  LoginProfileDataContext,
} from "../app"
import {
  CurrentPetDataContext,
  UndoContext,
} from "../templates/homepage/homepage"

import ChartIntro from "./helpers/chart-intro"
import Snackbar from "../snackbar/snackbar"
import ConfidenceMessageSnackbar from "../snackbar/confidenceMessageSnackbar"
import GrowthGuidanceSnackbar from "../snackbar/growthGuidanceSnackbar"
import BtnActions from "./helpers/btn-actions"
import ActionOptions from "./helpers/action-options"
import Undo from "./helpers/undo"
import WeightToggle from "./helpers/weight-toggle"
import ChartSkeleton from "../loading-indicators/chart-skeleton"

import ModalUpdatePetsWeight from "../modals/modal-update-pets-weight"
import ModalAddNeutering from "../modals/modal-add-neutering"
import ModalSocialshare from "../modals/modal-socialshare"
import GrowthChart from "./growth-chart"
import ScaleChart from "./scale-chart"
import FormLoadingIndicator from "../loading-indicators/form-loading-indicator"

function DataVisualization() {
  const skeletonLoadingContext = useContext(SkeletonLoadingContext)
  const snackbarContext = useContext(SnackbarContext)
  const growthChartSnackbarContext = useContext(GrowthChartSnackbarContext)
  const currentPetDataContext = useContext(CurrentPetDataContext)
  const loginProfileDataContext = useContext(LoginProfileDataContext)
  const undoContext = useContext(UndoContext)

  const [data, setData] = useState([])
  const [showActionOptions, setShowActionOptions] = useState(false)
  const [showModalWeight, setShowModalWeight] = useState(false)
  const [showAddNeutering, setShowAddNeutering] = useState(false)
  const [showModalSocialshare, setShowModalSocialshare] = useState(false)
  const [showScaleChart, setShowScaleChart] = useState(false)
  const [addedWeight, setAddedWeight] = useState({
    weight: "",
    weeks: "",
    confidenceMessage: { type: "", text: "" },
  })
  const [addedNeutering, setAddedNeutering] = useState("")
  const [addedNeuteringValue, setAddedNeuteringValue] = useState()

  const [addedWeightValues, setAddedWeightValues] = useState()
  const [addedWeightLimit, setAddedWeightLimit] = useState()

  const [updateAddedPetWeight, setUpdateAddedPetWeight] = useState(false)
  const [weightInitialValues, setWeightInitialValues] = useState({
    weight: "",
    measurementDate: new Date(),
  })
  const [weightShallowInitialValues, setWeightShallowInitialValues] = useState({
    weight: "",
    measurementDate: new Date(),
  })
  const [weightUnitLocal, setWeightUnitLocal] = useState(null)

  let weights,
    filteredWeights = [],
    percentiles = currentPetDataContext
  useEffect(() => {
    if (currentPetDataContext.state.weights) {
      weights = currentPetDataContext.state.weights.weights
      filteredWeights = weights.sort((a, b) => a.ageWeeks - b.ageWeeks)
      setData(filteredWeights)
    } else {
      setData([])
    }
  }, [currentPetDataContext])

  useEffect(() => {
    setWeightUnitLocal(loginProfileDataContext.state.owner.weight_unit)
  }, [])

  useEffect(() => {
    if (showScaleChart) {
      document.getElementsByTagName("body")[0].className = "overflow-lock"
    } else {
      document.getElementsByTagName("body")[0].className = ""
    }
  }, [showScaleChart])

  // console.log(addedWeight)
  // console.log(addedNeutering)
  return (
    <>
      <div id="puppyDataViz" className="puppy-data-viz">
        {skeletonLoadingContext.skeletonLoading && <FormLoadingIndicator/>}

        <GrowthChart
          data={data}
          percentiles={percentiles}
          setUpdateAddedPetWeight={setUpdateAddedPetWeight}
          setWeightInitialValues={setWeightInitialValues}
          setShowModalWeight={setShowModalWeight}
          setWeightShallowInitialValues={setWeightShallowInitialValues}
          weightUnitLocal={weightUnitLocal}
          setAddedWeightLimit={setAddedWeightLimit}
        />

        {data.length === 0 && <ChartIntro />}

        <div className="d-none d-lg-block">
          {snackbarContext.state.content !== "" && <Snackbar />}
          {snackbarContext.state.content === "" &&
            growthChartSnackbarContext.state.confidenceMessage !== "" && (
              <>
                <ConfidenceMessageSnackbar />
                <GrowthGuidanceSnackbar />
              </>
            )}
        </div>

        <WeightToggle
          weightUnitLocal={weightUnitLocal}
          setWeightUnitLocal={setWeightUnitLocal}
        />

        {loginProfileDataContext.state.pets.length !== 0 &&
          currentPetDataContext.state.petID && (
            <BtnActions
              showActionOptions={showActionOptions}
              setShowActionOptions={setShowActionOptions}
            />
          )}

        {showActionOptions && (
          <ActionOptions
            setShowActionOptions={setShowActionOptions}
            setShowModalWeight={setShowModalWeight}
            setShowAddNeutering={setShowAddNeutering}
            setShowModalSocialshare={setShowModalSocialshare}
          />
        )}

        {undoContext.state && (
          <Undo
            addedWeight={addedWeight}
            setAddedWeight={setAddedWeight}
            addedNeutering={addedNeutering}
            setAddedNeutering={setAddedNeutering}
            addedNeuteringValue={addedNeuteringValue}
            setAddedNeuteringValue={setAddedNeuteringValue}
            addedWeightValues={addedWeightValues}
            setAddedWeightValues={setAddedWeightValues}
            weightUnitLocal={weightUnitLocal}
          />
        )}

        <div
          className="position-absolute btn-scale-chart d-lg-none"
          onClick={() => setShowScaleChart(true)}
        >
          <figure className="mb-0">
            <img
              className="img-fluid"
              src="../../../icons/icon-scale.png"
              alt=""
            />
          </figure>
        </div>

        {showScaleChart && (
          <ScaleChart
            setShowScaleChart={setShowScaleChart}
            data={data}
            percentiles={percentiles}
            setUpdateAddedPetWeight={setUpdateAddedPetWeight}
            setWeightInitialValues={setWeightInitialValues}
            setShowModalWeight={setShowModalWeight}
            setWeightShallowInitialValues={setWeightShallowInitialValues}
            weightUnitLocal={weightUnitLocal}
          />
        )}

        <ModalUpdatePetsWeight
          setAddedWeight={setAddedWeight}
          updateAddedPetWeight={updateAddedPetWeight}
          setUpdateAddedPetWeight={setUpdateAddedPetWeight}
          weightInitialValues={weightInitialValues}
          setWeightInitialValues={setWeightInitialValues}
          weightShallowInitialValues={weightShallowInitialValues}
          addedWeightValues={addedWeightValues}
          setAddedWeightValues={setAddedWeightValues}
          setAddedNeutering={setAddedNeutering}
          addedWeightLimit={addedWeightLimit}
          weightUnitLocal={weightUnitLocal}
          show={showModalWeight}
          onHide={() => setShowModalWeight(false)}
        />
        <ModalAddNeutering
          setAddedWeight={setAddedWeight}
          setAddedNeutering={setAddedNeutering}
          setAddedNeuteringValue={setAddedNeuteringValue}
          show={showAddNeutering}
          onHide={() => setShowAddNeutering(false)}
        />
        <ModalSocialshare
          show={showModalSocialshare}
          onHide={() => setShowModalSocialshare(false)}
        />
      </div>
    </>
  )
}

export default DataVisualization
