import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import App from "../components/app"
import ServerMaintenance from "../components/server-maintenance"

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <App />
      {/* <ServerMaintenance/> */}
    </Layout>
  )
}
export default IndexPage
