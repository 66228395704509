import React, { useContext } from "react"

import ModalSignup from "../modals/modal-signup"
import ModalLogin from "../modals/modal-login"

import { ModalContext } from "../app"

function Signup() {
  const modalContext = useContext(ModalContext)
  return (
    <>
      <div className="d-flex flex-column justify-content-center h-100 welcome-screen__signup-content">
        {/* <h6 className="subtitle-1">Get started</h6>
        <p className="caption">
          Sign up for free to start tracking your pet's weight and get access
          to: <br />
          • Growth charts scientifically developed using data from 50,000
          healthy dogs (Salt et al, 2017, Salt et al 2020)
          <br />
          • Alerts if your puppy isn't growing as expected
          <br />
          • 10 different growth charts, catering for dogs up to 40kg (adult
          weight)
          <br />
          • Unlimited pet profiles
          <br />
          • Shareable charts that you can send to your vet or post on social
          media
        </p> */}
        <h5 className="text-mars-blue mb-4">
          Get started by creating your pet's profile below
        </h5>
        {/* <hr className="divider" /> */}
        <div className="d-grid">
          <button
            className="btn btn-outline-mars-blue"
            onClick={() => {
              modalContext.dispatch("showSignupModal")
            }}
          >
            Add My Pet
          </button>
        </div>
        <div className="text-center">
          <span
            className="btn-login d-inline-block text-mars-red"
            onClick={() => {
              modalContext.dispatch("showLoginModal")
            }}
          >
            Log In
          </span>
        </div>
        <figure className="mb-0 mx-auto">
          <img
            className="img-fluid"
            src="../silhouette-dog-2.svg"
            alt="Silhouette Dog 2"
          />
        </figure>
      </div>

      <ModalSignup
        show={modalContext.state.modalSignupShow}
        onHide={() => modalContext.dispatch("hideSignupModal")}
      />
      <ModalLogin
        show={modalContext.state.modalLoginShow}
        onHide={() => modalContext.dispatch("hideLoginModal")}
      />
    </>
  )
}

export default Signup
