import React from "react"

import { FacebookShareButton, TwitterShareButton } from "react-share"

import Modal from "react-bootstrap/Modal"

function ModalSocialshare(props) {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        centered
        animation
        scrollable
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6" className="text-mars-blue">
            Share profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="message">
            <p className="caption mb-0 mx-4">
              Your pet's growth chart along with the brief profile information
              such as pet's name, date of birth, gender and current weight will
              be shared.
            </p>
          </div>
          <p className="caption">Share</p>
          <div className="mb-3">
            <FacebookShareButton
              url={"https://stage.waltham.com/puppy-growth-chart"}
            >
              <div className="d-inline-flex align-items-center btn-text-icon">
                <figure className="flex-shrink-0 mb-0">
                  <img
                    className="img-fluid"
                    src="../../icons/icon-facebook.png"
                    alt=""
                  />
                </figure>
                <span className="d-inline-block btn-text">Facebook</span>
              </div>
            </FacebookShareButton>
          </div>
          <div>
            <TwitterShareButton
              url={"https://stage.waltham.com/puppy-growth-chart"}
            >
              <div className="d-inline-flex align-items-center btn-text-icon">
                <figure className="flex-shrink-0 mb-0">
                  <img
                    className="img-fluid"
                    src="../../icons/icon-twitter.png"
                    alt=""
                  />
                </figure>
                <span className="d-inline-block btn-text">X</span>
              </div>
            </TwitterShareButton>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-outline-mars-blue"
            onClick={props.onHide}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalSocialshare
