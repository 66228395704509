import React, { useContext } from "react"

import { LoginProfileDataContext } from "../../app"
import { CurrentPetDataContext } from "../../templates/homepage/homepage"
function ChartIntro() {
  const loginProfileDataContext = useContext(LoginProfileDataContext)
  const currentPetDataContext = useContext(CurrentPetDataContext)
  // console.log(currentPetDataContext)
  return (
    <>
      <div className="chart-intro d-flex flex-column justify-content-center align-items-center">
        <div className="chart-intro-content text-center">
          {loginProfileDataContext.state.pets.length !== 0 &&
            !currentPetDataContext.state.petID && (
              <>
                <figure>
                  <img
                    className="img-fluid"
                    src="/silhouette-cat-dog-lg.png"
                    alt=""
                  />
                </figure>
                <h6 className="text-center text-mars-blue">
                  Hi there!
                  <span className="d-block">
                    Choose the pet to see the chart
                  </span>
                </h6>
              </>
            )}
          {loginProfileDataContext.state.pets.length !== 0 &&
            currentPetDataContext.state.petID &&
            currentPetDataContext.state.species === "dog" &&
            currentPetDataContext.state.breed !== null && (
              <>
                <figure>
                  <img
                    src={`./puppy-icons/${currentPetDataContext.state.breed
                      .split(" ")
                      .join("-")
                      .toLowerCase()}.svg`}
                    onError={e => {
                      e.target.src = "./puppy-icons/puppy.svg"
                    }}
                    alt={`${currentPetDataContext.state.breed}`}
                    className="img-fluid"
                  />
                </figure>
                <h6 className="text-center text-mars-blue">
                  Add {currentPetDataContext.state.pet_name}'s weight to start
                  tracking their growth
                </h6>
              </>
            )}
          {loginProfileDataContext.state.pets.length !== 0 &&
            currentPetDataContext.state.petID &&
            currentPetDataContext.state.species === "dog" &&
            currentPetDataContext.state.breed === null && (
              <>
                <figure>
                  <img
                    src={`./puppy-icons/puppy.svg`}
                    alt={`${currentPetDataContext.state.breed}`}
                  />
                </figure>
                <h6 className="text-center text-mars-blue">
                  Add {currentPetDataContext.state.pet_name}'s weight to start
                  tracking their growth
                </h6>
              </>
            )}

          {loginProfileDataContext.state.pets.length !== 0 &&
            currentPetDataContext.state.petID &&
            currentPetDataContext.state.species === "cat" && (
              <>
                <figure>
                  <img
                    className="img-fluid"
                    src={`./puppy-icons/kitten.svg`}
                    alt=""
                  />
                </figure>
                <h6 className="text-center text-mars-blue">
                  Add {currentPetDataContext.state.pet_name}'s weight to start
                  tracking their growth
                </h6>
              </>
            )}
        </div>
        {/* <div className="chart-intro-content text-center">
          {loginProfileDataContext.state.pets.length !== 0 &&
            !currentPetDataContext.state.petID ? (
              <>
                <figure>
                  <img
                    className="img-fluid"
                    src="/silhouette-cat-dog-lg.png"
                    alt=""
                  />
                </figure>
                <h6 className="text-center text-mars-blue">
                  Hi there!
                  <span className="d-block">
                    Choose the pet to see the chart
                  </span>
                </h6>
              </>
            )
          : loginProfileDataContext.state.pets.length !== 0 &&
            currentPetDataContext.state.petID &&
            currentPetDataContext.state.species === "dog"  &&
            currentPetDataContext.state.breed !== null ? (
              <>
                <figure>
                <img
                  src={`./puppy-icons/${currentPetDataContext.state.breed
                    .split(" ")
                    .join("-")
                    .toLowerCase()}.svg`}
                  onError={(e) => {
                    e.target.src = "./puppy-icons/puppy.svg";
                  }}
                  alt={`${currentPetDataContext.state.breed}`}
                />
                </figure>
                <h6 className="text-center text-mars-blue">
                  Add {currentPetDataContext.state.pet_name}'s weight to start
                  tracking their growth
                </h6>
              </>
            ):
            loginProfileDataContext.state.pets.length !== 0 &&
            currentPetDataContext.state.petID &&
            currentPetDataContext.state.species === "dog"  &&
            currentPetDataContext.state.breed === null ? (
              <>
                <figure>
                <img src={`./puppy-icons/puppy.svg`}
                  alt={`${currentPetDataContext.state.breed}`}
                />
                </figure>
                <h6 className="text-center text-mars-blue">
                  Add {currentPetDataContext.state.pet_name}'s weight to start
                  tracking their growth
                </h6>
              </>
            ):

          loginProfileDataContext.state.pets.length !== 0 &&
            currentPetDataContext.state.petID &&
            currentPetDataContext.state.species === "cat" && (
              <>
                <figure>
                  <img
                    className="img-fluid"
                    src="/silhouette-cat-lg.png"
                    alt=""
                  />
                </figure>
                <h6 className="text-center text-mars-blue">
                  Add {currentPetDataContext.state.pet_name}'s weight to start
                  tracking their growth
                </h6>
              </>
            )}
        </div> */}
      </div>
    </>
  )
}

export default ChartIntro
