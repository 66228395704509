import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper"

function WelcomeCarouselDesktop() {
  return (
    <>
      <div className="welcome-screen__chart">
        <div className="welcome-screen_carousel">
          <Swiper
            pagination={{
              el: ".swiper-pagination",
              type: "bullets",
            }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            modules={[Navigation, Pagination]}
            className="welcome-screen-swiper"
          >
            {/* <SwiperSlide>
              <h6 className="text-mars-blue mb-3">
                See the chart for different puppies
              </h6>
              <figure className="mb-0">
                <img
                  className="img-fluid"
                  src="../../welcome-carousel/desktop/slide1.png"
                  alt=""
                />
              </figure>
            </SwiperSlide> */}
            {/* <SwiperSlide>
              <h6 className="text-mars-blue mb-3">Add Actions</h6>
              <figure className="mb-0">
                <img
                  className="img-fluid"
                  src="../../welcome-carousel/desktop/slide2.png"
                  alt=""
                />
              </figure>
            </SwiperSlide> */}
            {/* <SwiperSlide>
              <h6 className="text-mars-blue mb-3">Add New Puppy</h6>
              <figure className="mb-0">
                <img
                  className="img-fluid"
                  src="../../welcome-carousel/desktop/add-pet.gif"
                  alt=""
                />
              </figure>
            </SwiperSlide>
            <SwiperSlide>
              <h6 className="text-mars-blue mb-3">Update Weight</h6>
              <figure className="mb-0">
                <img
                  className="img-fluid"
                  src="../../welcome-carousel/desktop/update-weight.gif"
                  alt=""
                />
              </figure>
            </SwiperSlide> */}
            <SwiperSlide>
              {/* <h6 className="text-mars-blue mb-3">Add Weights</h6> */}
              <figure className="mb-0">
                <img
                  className="img-fluid"
                  src="../../welcome-carousel/desktop/add-weights-one.gif"
                  alt=""
                />
              </figure>
            </SwiperSlide>
            <SwiperSlide>
              {/* <h6 className="text-mars-blue mb-3">Add Weights</h6> */}
              <figure className="mb-0">
                <img
                  className="img-fluid"
                  src="../../welcome-carousel/desktop/add-weights-two.gif"
                  alt=""
                />
              </figure> 
            </SwiperSlide>
          </Swiper>
          <div className="swiper-pagination"></div>

          <div className="swiper-button-prev"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </>
  )
}

export default WelcomeCarouselDesktop
