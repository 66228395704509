import React, { useContext } from "react"

import { GrowthChartSnackbarContext } from "../app"
import { ToggleGrowthGuidanceSnackbarContext } from "../templates/homepage/homepage"

function GrowthGuidanceSnackbar() {
  const growthChartSnackbarContext = useContext(GrowthChartSnackbarContext)
  const toggleGrowthGuidanceSnackbarContext = useContext(
    ToggleGrowthGuidanceSnackbarContext
  )
  const toggleSnackbarHandler = () => {
    toggleGrowthGuidanceSnackbarContext.setState(
      !toggleGrowthGuidanceSnackbarContext.state
    )
  }

  return (
    <>
      {growthChartSnackbarContext.state.growthGuidance !== "" && (
        <>
          {/* {toggleGrowthGuidanceSnackbarContext.state && (
            <div
              className="growth-chart-snackbar-btn growth-guidance"
              onClick={toggleSnackbarHandler}
            ></div>
          )} */}

          <div
            className="growth-chart-snackbar-btn growth-guidance"
            onClick={toggleSnackbarHandler}
          ></div>

          <div
            className={`growth-chart growth-guidance alert alert-shadow alert-fade-in icon icon-paw alert-light icon-info ${
              toggleGrowthGuidanceSnackbarContext.state && "visually-hidden"
            }`}
            role="alert"
          >
            {growthChartSnackbarContext.state.growthGuidance}
            <button
              type="button"
              className="btn-close d-lg-none"
              aria-label="Close"
              onClick={toggleSnackbarHandler}
            ></button>
          </div>
        </>
      )}
    </>
  )
}

export default GrowthGuidanceSnackbar
