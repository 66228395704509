import React, { useState, useContext } from "react"
import Modal from "react-bootstrap/Modal"

import axios from "axios"
import moment from "moment"

import LoginForm from "../forms/form-login/form-login"
import OTPBox from "../forms/otp-box/otp-box"

import {
  ResetPasswordContext,
  IsLoginContext,
  LoginProfileDataContext,
  OTPRequestContext,
  LoadingContext,
  ModalContext,
  SnackbarContext,
  PetDataContext,
  FetchDataContext,
  AddedPetIdContext,
} from "../app"

const initialValues = {
  emailAddress: "",
  password: "",
}

function ModalLogin(props) {
  const [data, setData] = useState(initialValues)
  const [loginForm, setLoginForm] = useState(true)
  const [credentialsError, setCredentialsError] = useState("")
  const [passwordNotChanged, setPasswordNotChanged] = useState(false)
  const [forgotPasswordResponseData, setForgotPasswordResponseData] = useState(
    {}
  )
  const [verifyOTPResponse, setVerifyOTPResponse] = useState({})
  const [inCorrectEmail, setInCorrectEmail] = useState(false)
  const [OTPError, setOTPError] = useState("")

  const isLogin = useContext(IsLoginContext)
  const loadingContext = useContext(LoadingContext)
  const resetPasswordContext = useContext(ResetPasswordContext)
  const loginProfileDataContext = useContext(LoginProfileDataContext)
  const petDataContext = useContext(PetDataContext)
  const otpRequestContext = useContext(OTPRequestContext)
  const modalContext = useContext(ModalContext)
  const snackbarContext = useContext(SnackbarContext)
  const fetchDataContext = useContext(FetchDataContext)
  const addedPetIdContext = useContext(AddedPetIdContext)

  const makeAddPuppyRequest = response => {
    loadingContext.setLoading(true)

    const dataToSend = {
      pet_name: petDataContext.state.petName,
      dob: moment(petDataContext.state.birthDate).format("YYYY-MM-DD"),
      do_you_know_your_pets_breed: petDataContext.state.knowPetsBreed,
      // breed: formData.mixedBreed === true ? null : formData.petsBreed,
      breed:
        petDataContext.state.species === "cat" &&
        petDataContext.state.mixedBreed
          ? null
          : petDataContext.state.petsBreed,
      mixed_breed: petDataContext.state.mixedBreed === true ? "yes" : null,
      breed_size: petDataContext.state.petsAdultWeight,
      sex: petDataContext.state.petsGender,
      neuter_date: null,
      species: petDataContext.state.species, //cat or dog allowed
    }
    var config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/add-pet?_queryFilter1=mail eq ${response.data.owner.email}&_queryFilter2=_id eq ${response.data.owner._id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${response.data.owner.token}`,
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)
        if (response.data._id) {
          addedPetIdContext.setState(response.data.petID)
          fetchDataContext()
          snackbarContext.setState({
            classes: "alert-success icon-success",
            content: "Pet's profile was created successfully.",
          })
          petDataContext.setState({})
        }
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
      })
  }

  const makeLoginRequest = formData => {
    loadingContext.setLoading(true)
    const dataToSend = {
      email: formData.emailAddress,
      password: formData.password,
    }

    var config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)
        if (
          response.data.error &&
          response.data.error_description === "Authentication Failed"
        ) {
          setCredentialsError("Incorrect email or password.")
        } else if (
          response.data.error &&
          response.data.error_description ===
            "Authentication Failed Warning: You will be locked out after 2 more failure(s)."
        ) {
          setCredentialsError("You will be locked out after 2 more failure(s).")
        } else if (
          response.data.error &&
          response.data.error_description ===
            "Authentication Failed Warning: You will be locked out after 1 more failure(s)."
        ) {
          setCredentialsError("You will be locked out after 1 more failure(s).")
        } else if (
          response.data.error &&
          response.data.error_description === "Your account has been locked."
        ) {
          setCredentialsError(
            "Your account is temporarily locked. please retry in 5 minutes."
          )
        } else if (response.data.name === "Internal Server Error") {
          modalContext.dispatch("hideLoginModal")
          snackbarContext.setState({
            classes: "alert-danger icon-failed",
            content: response.data.message,
          })
        } else {
          loginProfileDataContext.setState(response.data)
          modalContext.dispatch("hideLoginModal")
          isLogin.setIsLogin(true)
          localStorage.setItem("token", response.data.owner.token)
          if (Object.keys(petDataContext.state).length !== 0) {
            makeAddPuppyRequest(response)
          }
        }
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
      })
  }
  const makeForgotPasswordRequest = emailAddress => {
    loadingContext.setLoading(true)
    setOTPError("")
    const dataToSend = {
      email: emailAddress,
    }

    var config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/forgotpassword`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)
        if (response.data.message && response.data.reason) {
          setCredentialsError("")
          setInCorrectEmail(true)
        } else {
          setLoginForm(false)
          setForgotPasswordResponseData(response.data)
        }
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
      })
  }

  const makeVerifyOtpRequest = (
    OTPValue,
    setOTPMatched,
    setOTPNotMatched,
    setOTPResendEnable,
    setOTPTimerStart,
    setOTPTimerDuration
  ) => {
    loadingContext.setLoading(true)
    const dataToSend = {
      verifyOTPAuthID: forgotPasswordResponseData.verifyOTPAuthID,
      verifyOTPCookie: forgotPasswordResponseData.verifyOTPCookie,
      otp: OTPValue,
    }

    var config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/verify-otp`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)
        if (response.data.message === "Login failure") {
          setOTPNotMatched(true)
        } else if (
          response.data.message ===
          "Warning: You will be locked out after 2 more failure(s)."
        ) {
          setOTPError("You will be locked out after 2 more failure(s).")
          setOTPNotMatched(true)
        } else if (
          response.data.message ===
          "Warning: You will be locked out after 1 more failure(s)."
        ) {
          setOTPError("You will be locked out after 1 more failure(s).")
          setOTPNotMatched(true)
        } else if (response.data.message === "User Locked Out.") {
          setOTPError(
            "Your account is temporarily locked. please retry in 5 minutes."
          )
          setOTPNotMatched(false)
          setOTPResendEnable(false)
          setOTPTimerStart(false)
          setOTPTimerDuration(30)
        } else if (
          response.data.callbacks[0].type === "ValidatedCreatePasswordCallback"
        ) {
          setOTPError("")
          setOTPMatched(true)
          setOTPNotMatched(false)
          setOTPResendEnable(false)
          setOTPTimerStart(false)
          setOTPTimerDuration(30)
          setVerifyOTPResponse(response.data)
        } else {
          setOTPNotMatched(true)
        }
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
        setOTPNotMatched(true)
      })
  }
  const makeResetPasswordRequest = formData => {
    loadingContext.setLoading(true)
    const dataToSend = {
      resetPasswordAuthID: verifyOTPResponse.resetPasswordAuthID,
      resetPasswordCookie: verifyOTPResponse.resetPasswordCookie,
      password: formData.newPassword,
    }

    var config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/reset-password`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)
        if (response.data.failedPolicies) {
          otpRequestContext.dispatch({
            type: "PASSWORD_FAILED",
            passwordErrorText: response.data.failedPolicies.password.message,
          })
          setPasswordNotChanged(true)
        } else if (response.data.tokenId) {
          otpRequestContext.dispatch({
            type: "OTP_SENT_SUCCESS",
            authId: response.data.authId,
            ownerEnterCookie: response.data.owner_enter_cookie,
          })
          setPasswordNotChanged(false)
          modalContext.dispatch("hideLoginModal")
          snackbarContext.setState({
            classes: "alert-success icon-success",
            content: "Your password was changed successfully.",
          })
        } else {
          setPasswordNotChanged(true)
        }
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
      })
  }

  return (
    <>
      <Modal
        {...props}
        size="lg"
        centered
        animation
        scrollable
        onEnter={() => {
          resetPasswordContext.setState(true)
        }}
        onExited={() => {
          setLoginForm(true)
          setData(initialValues)
          resetPasswordContext.setState(false)
          setCredentialsError("")
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6" className="text-mars-blue">
            {!loginForm && "Reset password"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loginForm ? (
            <LoginForm
              data={data}
              setData={setData}
              setLoginForm={setLoginForm}
              makeLoginRequest={makeLoginRequest}
              credentialsError={credentialsError}
              setCredentialsError={setCredentialsError}
              inCorrectEmail={inCorrectEmail}
              setInCorrectEmail={setInCorrectEmail}
              makeForgotPasswordRequest={makeForgotPasswordRequest}
            />
          ) : (
            <OTPBox
              emailAddress={data.emailAddress}
              forgotPassword={true}
              title={
                "To complete password reset process, we need to verify your email."
              }
              makeForgotPasswordRequest={makeForgotPasswordRequest}
              makeVerifyOtpRequest={makeVerifyOtpRequest}
              makeResetPasswordRequest={makeResetPasswordRequest}
              passwordNotChanged={passwordNotChanged}
              setPasswordNotChanged={setPasswordNotChanged}
              OTPError={OTPError}
              setOTPError={setOTPError}
            />
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          {loginForm ? (
            <>
              <button
                type="button"
                className="btn btn-outline-mars-blue"
                onClick={props.onHide}
              >
                Close
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-outline-mars-blue"
                onClick={() => setLoginForm(true)}
              >
                Back
              </button>
            </>
          )}
          <button type="submit" className="btn btn-mars-blue" form="myform">
            {loginForm ? "Log in" : "Submit"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalLogin
