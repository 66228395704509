import React, { useState, useEffect, useContext, useRef } from "react"
import { select, scaleLinear, axisBottom, axisLeft, line, area } from "d3"
import moment from "moment"
import ResizeObserver from "resize-observer-polyfill"

import {
  LoginProfileDataContext,
  GrowthChartSnackbarContext,
  TabInitialKeyContext,
} from "../app"
import {
  CurrentPetDataContext,
  TabActiveKeyContext,
} from "../templates/homepage/homepage"

const useResizeObserver = ref => {
  const [dimensions, setDimensions] = useState(null)

  useEffect(() => {
    const observeTarget = ref.current
    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        setDimensions(entry.contentRect)
      })
    })
    resizeObserver.observe(observeTarget)
    return () => {
      resizeObserver.unobserve(observeTarget)
    }
  }, [ref])
  return dimensions
}

function GrowthChart({
  data,
  percentiles,
  setUpdateAddedPetWeight,
  setWeightInitialValues,
  setShowModalWeight,
  setWeightShallowInitialValues,
  weightUnitLocal,
  setAddedWeightLimit,
}) {
  const loginProfileDataContext = useContext(LoginProfileDataContext)
  const currentPetDataContext = useContext(CurrentPetDataContext)
  const growthChartSnackbarContext = useContext(GrowthChartSnackbarContext)

  const tabInitialKeyContext = useContext(TabInitialKeyContext)
  const tabActiveKeyContext = useContext(TabActiveKeyContext)

  const svgWrapperRef = useRef()
  const svgRef = useRef()
  const dimensions = useResizeObserver(svgWrapperRef)

  // console.log(loginProfileDataContext)
  // console.log(percentiles)
  
  // console.log(tabActiveKeyContext.state)

  useEffect(() => {
    // Convert Neutering Date to Weeks
    const birthday = moment(new Date(percentiles.state.dob))
    const selectedNeuteringDate = moment(
      new Date(percentiles.state.neuter_date)
    )
    let diff = moment.duration(selectedNeuteringDate.diff(birthday))
    let weeks = diff.asWeeks()
    weeks = weeks.toFixed(1)

    const neuteringPosition = [{ age: +weeks, weight: 1 }]

    if (!dimensions) return

    // if (tabActiveKeyContext.state === undefined) {
    //   growthChartSnackbarContext.setState({
    //     classes: "alert-light icon-info",
    //     content: `${
    //       percentiles.state.weights.weights.length !== 0
    //         ? percentiles.state.weights.recommendation.growthGuidance.text
    //         : ""
    //     }`,
    //   })
    // }
    if (
      tabActiveKeyContext.state === undefined ||
      tabInitialKeyContext.state === "growthChart"
    ) {
      growthChartSnackbarContext.setState({
        classes: `${
          percentiles.state.weights.weights.length !== 0
            ? percentiles.state.weights.recommendation.confidenceMessage.type
            : ""
        }`,
        confidenceMessage: `${
          percentiles.state.weights.weights.length !== 0
            ? percentiles.state.weights.recommendation.confidenceMessage.text
            : ""
        }`,
        growthGuidance: `${
          percentiles.state.weights.weights.length !== 0
            ? percentiles.state.weights.recommendation.growthGuidance.text
            : ""
        }`,
      })
    }

    // console.log(currentPetDataContext.state.weights.weights[0].ageWeeks)
    // console.log(percentiles.state)

    let shadeOne,
      shadeTwo,
      shadeThree,
      shadeFour,
      shadeFive,
      shadeSix,
      shadeSeven,
      shadeEight
    if (
      loginProfileDataContext.state.pets.length !== 0 &&
      percentiles.state.weights.weights.length !== 0
    ) {
      const centileShadingObj =
        percentiles.state.weights.recommendation.centileShading
      const centileShadingObjSorted = centileShadingObj.sort(
        (a, b) => a.min - b.min
      )
      shadeOne = centileShadingObjSorted[0].status
      shadeTwo = centileShadingObjSorted[1].status
      shadeThree = centileShadingObjSorted[2].status
      shadeFour = centileShadingObjSorted[3].status
      shadeFive = centileShadingObjSorted[4].status
      shadeSix = centileShadingObjSorted[5].status
      shadeSeven = centileShadingObjSorted[6].status
      shadeEight = centileShadingObjSorted[7].status
      // console.log(
      //   shadeOne,
      //   shadeTwo,
      //   shadeThree,
      //   shadeFour,
      //   shadeFive,
      //   shadeSix,
      //   shadeSeven,
      //   shadeEight
      // )
    }

    // set the dimensions and margins of the graph - Desktop
    // let margin = { top: 10, right: 50, bottom: 60, left: 70 },
    //   width = dimensions.width - margin.left - margin.right,
    //   height = 600 - margin.top - margin.bottom

    // console.log(
    //   Object.keys(percentiles.state.codes_changed["0.02"])[
    //     Object.keys(percentiles.state.codes_changed["0.02"]).length - 1
    //   ]
    // )

    let percentileLineExtremeData,
      xAxisLimit = 55,
      yAxisLimit = 12
    if (percentiles.state.codes_changed) {
      const percentileLineObject = Object.entries(
        percentiles.state.codes_changed["0.996"]
      )
      percentileLineExtremeData = percentileLineObject.sort(
        (a, b) => a[0] - b[0]
      )

      percentileLineExtremeData = Math.ceil(
        percentileLineExtremeData[percentileLineExtremeData.length - 1][1]
      )
      xAxisLimit = percentileLineObject.sort((a, b) => a[0] - b[0])
      xAxisLimit = Number(xAxisLimit[xAxisLimit.length - 1][0])
    }

    // console.log(xAxisLimit + 5)

    // let ageWeeksLimit = 0
    // if (data.length !== 0) {
    //   ageWeeksLimit = data[data.length - 1].ageWeeks
    // }
    /**** ------ ****/
    // if (currentPetDataContext.state.weights.weights.length !== 0) {
    //   lastWeightAdded =
    //     currentPetDataContext.state.weights.weights[
    //       currentPetDataContext.state.weights.weights.length - 1
    //     ].weightKg
    // }

    let ageWeeksAxis
    // if (xAxisLimit > ageWeeksLimit) {
    //   ageWeeksAxis = xAxisLimit + 5
    // } else {
    //   ageWeeksAxis = ageWeeksLimit + 5
    // }
    ageWeeksAxis = xAxisLimit + 5

    // console.log(data, ageWeeksLimit)

    if (percentileLineExtremeData > 5 && percentileLineExtremeData < 10) {
      yAxisLimit = percentileLineExtremeData + 2
    } else if (
      percentileLineExtremeData >= 10 &&
      percentileLineExtremeData < 15
    ) {
      yAxisLimit = percentileLineExtremeData + 4
    } else if (
      percentileLineExtremeData >= 15 &&
      percentileLineExtremeData < 20
    ) {
      yAxisLimit = percentileLineExtremeData + 5
    } else if (
      percentileLineExtremeData >= 20 &&
      percentileLineExtremeData < 25
    ) {
      yAxisLimit = percentileLineExtremeData + 6
    } else if (
      percentileLineExtremeData >= 25 &&
      percentileLineExtremeData < 30
    ) {
      yAxisLimit = percentileLineExtremeData + 7
    } else if (
      percentileLineExtremeData >= 30 &&
      percentileLineExtremeData < 35
    ) {
      yAxisLimit = percentileLineExtremeData + 8
    } else if (
      percentileLineExtremeData >= 35 &&
      percentileLineExtremeData < 40
    ) {
      yAxisLimit = percentileLineExtremeData + 9
    } else if (
      percentileLineExtremeData >= 40 &&
      percentileLineExtremeData < 50
    ) {
      yAxisLimit = percentileLineExtremeData + 10
    }

    let margin,
      divHeight,
      width,
      height,
      xLabelOffset,
      yLabelOffset,
      widthNeutering,
      heightNeutering,
      rCircle,
      rOuterCircle,
      widthStroke,
      wRectWeight,
      hRectWeight,
      wRectAge,
      hRectAge,
      tooltipFontSize

    if (dimensions.width < 500) {
      margin = { top: 10, right: 10, bottom: 35, left: 30 }
      divHeight = 300
      xLabelOffset = 25
      yLabelOffset = 20
      widthNeutering = 16
      heightNeutering = 16
      rCircle = 4
      rOuterCircle = 6
      widthStroke = 1.5
      wRectWeight = 30
      hRectWeight = 8
      wRectAge = 30
      hRectAge = 8
      tooltipFontSize = 4
    } else {
      margin = { top: 10, right: 50, bottom: 60, left: 70 }
      divHeight = 600
      xLabelOffset = 40
      yLabelOffset = 35
      widthNeutering = 24
      heightNeutering = 24
      rCircle = 6
      rOuterCircle = 9
      widthStroke = 2.5
      wRectWeight = 56
      hRectWeight = 24
      wRectAge = 74
      hRectAge = 24
      tooltipFontSize = 12
    }

    // Weight Unit Conversion
    let weightUnitConversion = value => {
      if (weightUnitLocal === "lbs") {
        return value * 2.20462
      } else {
        return value
      }
    }

    width = dimensions.width - margin.left - margin.right
    height = divHeight - margin.top - margin.bottom

    // append the svg object to the body of the page
    select("#svgWrapper").select(".main").remove()
    const svg = select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)

    const main = svg
      .append("g")
      .attr("class", "main")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    // x axis
    const xScale = scaleLinear().domain([0, ageWeeksAxis]).range([0, width])

    const xAxis = axisBottom(xScale)
    main
      .append("g")
      .attr("transform", "translate(0, " + height + ")")
      .attr("class", "x axis")
      .call(xAxis)

    // y axis
    const yScale = scaleLinear()
      .domain([0, weightUnitConversion(yAxisLimit)])
      .range([height, 0])
    const yAxis = axisLeft(yScale)
    main.append("g").attr("class", "y axis").call(yAxis)

    // Set added weight limit
    setAddedWeightLimit(weightUnitConversion(yAxisLimit))

    // x label
    main
      .append("text")
      .attr("class", "x label")
      .attr("text-anchor", "middle")
      .attr("x", width / 2)
      .attr("y", height + xLabelOffset)
      .text("Age in Weeks")

    // y label
    main
      .append("text")
      .attr("class", "y label")
      .attr("text-anchor", "middle")
      .attr("y", -yLabelOffset)
      .attr("x", -height / 2)
      .attr("transform", "rotate(-90)")
      .text(`Weight in ${weightUnitLocal}`)

    // Creation of Percentile Lines
    if (
      loginProfileDataContext.state.pets.length !== 0 &&
      percentiles.state.codes_changed
    ) {
      const percentileLineObject = percentiles.state.codes_changed

      // console.log(percentileLineObject)

      const percentileLines = line()
        .x(d => xScale(d[0]))
        .y(d => yScale(weightUnitConversion(d[1])))

      const percentilesWrapper = main
        .append("g")
        .attr("class", "percentiles-wrapper")

      for (const key in percentileLineObject) {
        const lines = Object.entries(percentileLineObject[key])
        percentilesWrapper
          .append("g")
          .attr("class", "percentile")
          .append("path")
          .attr("d", percentileLines(lines.sort((a, b) => a[0] - b[0])))
      }
      for (const key in percentileLineObject) {
        const labels = Object.entries(percentileLineObject[key])
        const labelsSorted = labels.sort((a, b) => a[0] - b[0])
        const XYLastValue = labelsSorted[labelsSorted.length - 1]
        // console.log(XYLastValue)
        percentilesWrapper
          .append("text")
          .attr("class", "percentile-label")
          .attr("text-anchor", "end")
          .text(
            `${Number(weightUnitConversion(XYLastValue[1])).toFixed(
              1
            )}${weightUnitLocal}`
          )
          .attr("x", xScale(XYLastValue[0]))
          .attr("y", yScale(weightUnitConversion(XYLastValue[1])) - 4)
      }
    }

    if (percentiles.state.d3Obj) {
      // Creation of Percentile Areas
      const percentileAreaObject = percentiles.state.d3Obj
      const percentileAreas = area()
        .x0(d => xScale(d.x0))
        .y0(d => yScale(weightUnitConversion(d.y0)))
        .x1(d => xScale(d.x1))
        .y1(d => yScale(weightUnitConversion(d.y1)))

      const percentilesAreaWrapper = main
        .append("g")
        .attr("class", "percentiles-area-wrapper")

      // for (const key of Object.keys(percentileAreaObject)) {
      //   percentilesAreaWrapper
      //     .append("g")
      //     .attr("class", "percentile-area")
      //     .append("path")
      //     .attr(
      //       "d",
      //       percentileAreas(percentileAreaObject[key].sort((a, b) => a.x0 - b.x0))
      //     )
      // }
      // console.log(percentileAreaObject)
      /***** 0.004_0.02 *****/
      percentilesAreaWrapper
        .append("g")
        .attr("class", "percentile-area")
        .append("path")
        .attr("class", () => {
          if (shadeOne === "Warning") {
            return "warning"
          } else if (shadeOne === "Danger") {
            return "danger"
          } else if (shadeOne === "On track") {
            return "on-track"
          } else {
            return "no-shading"
          }
        })
        .attr(
          "d",
          percentileAreas(
            percentileAreaObject["0.004_0.02"].sort((a, b) => a.x0 - b.x0)
          )
        )
      /***** 0.02_0.09 *****/
      percentilesAreaWrapper
        .append("g")
        .attr("class", "percentile-area")
        .append("path")
        .attr("class", () => {
          if (shadeTwo === "Warning") {
            return "warning"
          } else if (shadeTwo === "Danger") {
            return "danger"
          } else if (shadeTwo === "On track") {
            return "on-track"
          } else {
            return "no-shading"
          }
        })
        .attr(
          "d",
          percentileAreas(
            percentileAreaObject["0.02_0.09"].sort((a, b) => a.x0 - b.x0)
          )
        )
      /***** 0.09_0.25 *****/
      percentilesAreaWrapper
        .append("g")
        .attr("class", "percentile-area")
        .append("path")
        .attr("class", () => {
          if (shadeThree === "Warning") {
            return "warning"
          } else if (shadeThree === "Danger") {
            return "danger"
          } else if (shadeThree === "On track") {
            return "on-track"
          } else {
            return "no-shading"
          }
        })
        .attr(
          "d",
          percentileAreas(
            percentileAreaObject["0.09_0.25"].sort((a, b) => a.x0 - b.x0)
          )
        )

      /***** 0.25_0.50 *****/
      percentilesAreaWrapper
        .append("g")
        .attr("class", "percentile-area")
        .append("path")
        .attr("class", () => {
          if (shadeFour === "Warning") {
            return "warning"
          } else if (shadeFour === "Danger") {
            return "danger"
          } else if (shadeFour === "On track") {
            return "on-track"
          } else {
            return "no-shading"
          }
        })
        .attr(
          "d",
          percentileAreas(
            percentileAreaObject["0.25_0.50"].sort((a, b) => a.x0 - b.x0)
          )
        )
      /***** 0.50_0.75 *****/
      percentilesAreaWrapper
        .append("g")
        .attr("class", "percentile-area")
        .append("path")
        .attr("class", () => {
          if (shadeFive === "Warning") {
            return "warning"
          } else if (shadeFive === "Danger") {
            return "danger"
          } else if (shadeFive === "On track") {
            return "on-track"
          } else {
            return "no-shading"
          }
        })
        .attr(
          "d",
          percentileAreas(
            percentileAreaObject["0.50_0.75"].sort((a, b) => a.x0 - b.x0)
          )
        )

      /***** 0.75_0.91 *****/
      percentilesAreaWrapper
        .append("g")
        .attr("class", "percentile-area")
        .append("path")
        .attr("class", () => {
          if (shadeSix === "Warning") {
            return "warning"
          } else if (shadeSix === "Danger") {
            return "danger"
          } else if (shadeSix === "On track") {
            return "on-track"
          } else {
            return "no-shading"
          }
        })
        .attr(
          "d",
          percentileAreas(
            percentileAreaObject["0.75_0.91"].sort((a, b) => a.x0 - b.x0)
          )
        )

      /***** 0.91_0.98 *****/
      percentilesAreaWrapper
        .append("g")
        .attr("class", "percentile-area")
        .append("path")
        .attr("class", () => {
          if (shadeSeven === "Warning") {
            return "warning"
          } else if (shadeSeven === "Danger") {
            return "danger"
          } else if (shadeSeven === "On track") {
            return "on-track"
          } else {
            return "no-shading"
          }
        })
        .attr(
          "d",
          percentileAreas(
            percentileAreaObject["0.91_0.98"].sort((a, b) => a.x0 - b.x0)
          )
        )
      /***** 0.98_0.996 *****/
      percentilesAreaWrapper
        .append("g")
        .attr("class", "percentile-area")
        .append("path")
        .attr("class", () => {
          if (shadeEight === "Warning") {
            return "warning"
          } else if (shadeEight === "Danger") {
            return "danger"
          } else if (shadeEight === "On track") {
            return "on-track"
          } else {
            return "no-shading"
          }
        })
        .attr(
          "d",
          percentileAreas(
            percentileAreaObject["0.98_0.996"].sort((a, b) => a.x0 - b.x0)
          )
        )
    }

    // Creation of Neutering
    if (weeks > 0.0) {
      const neutering = main.append("g").attr("class", "neutering")
      neutering
        .append("image")
        .data(neuteringPosition)
        .join("image")
        .attr("href", "../../../icons/icon-neutering.png")
        .attr("width", widthNeutering)
        .attr("height", heightNeutering)
        .attr("x", function (d) {
          return xScale(d.age) - widthNeutering / 2
        })
        .attr("y", function (d) {
          return yScale(weightUnitConversion(d.weight)) - heightNeutering / 2
        })
        .on("touchstart mouseover", function (d) {
          d.preventDefault()
          let { age, weight } = select(this).data()[0]
          const neuteringTooltip = main
            .append("g")
            .attr("class", "neuteringTooltip")
            .attr(
              "transform",
              `translate(${xScale(age) - 42}, ${yScale(weight) - 22.5})`
            )

          neuteringTooltip
            .append("rect")
            .attr("width", 84)
            .attr("height", 45)
            .attr("rx", 3)
            .on("mouseout", removeNeuteringTooltip)

          neuteringTooltip.append("text").html(
            `
          <tspan x="8" dy="1.5em">Neutering</tspan>
          <tspan x="8" dy="1.2em">${moment(
            percentiles.state.neuter_date
          ).format("D MMM YYYY")}</tspan>
        `
          )
        })
    }

    // Remove neutering tooltip
    function removeNeuteringTooltip() {
      main.select(".neuteringTooltip").style("opacity", 0).remove()
    }

    // Change Ticks to Circles
    const ticks = main.selectAll(".axis").selectAll(".tick")
    ticks.each(function () {
      select(this).append("circle").attr("r", 2.5)
    })
    ticks.selectAll("line").remove()

    //Draw a grid
    const xAxisGrid = axisBottom(xScale)
      .tickSize(height)
      .tickFormat("")
      .ticks(10)
    main.append("g").attr("class", "x axis-grid").call(xAxisGrid)

    const filteredData = data.filter(value => value.ageWeeks <= xAxisLimit)

    // console.log(data)
    // console.log(filteredData)

    const path = main.selectAll(".lineTest").data([filteredData]).join("path")

    // Sorting Filtered Data by Ageweeks
    const sortedFilteredData = filteredData.sort((a, b) => {
      return b.ageWeeks - a.ageWeeks
    })

    // console.log(sortedFilteredData)

    // Updata the line
    path
      .attr("class", "lineTest")
      .attr(
        "d",
        line()
          .x(d => xScale(d.ageWeeks))
          .y(d => yScale(weightUnitConversion(d.weightKg)))
      )
      .attr("fill", "none")
      .attr("stroke", "#0000a0")
      .attr("stroke-width", widthStroke)

    // console.log(filteredData)
    // Creation of tooltip
    const circles = main
      .append("g")
      .attr("class", "data-circles")
      .selectAll("circle")
      .data(filteredData)
      .join("circle")
      .on("touchstart mouseover", function (d) {
        d.preventDefault()
        let { ageWeeks, date, percentile, weightKg } = select(this).data()[0]
        // console.log(ageWeeks, date, percentile, weightKg)

        main
          .insert("g", ".data-circles")
          .attr("class", "circle chart-tooltip")
          .append("circle")
          .attr("r", rOuterCircle)
          .attr("cx", xScale(ageWeeks))
          .attr("cy", yScale(weightUnitConversion(weightKg)))
          .attr("fill", "#fff")
          .attr("stroke", "#0000a0")
        main
          .append("g")
          .attr("class", "rect chart-tooltip")
          .append("rect")
          .attr("width", wRectWeight)
          .attr("height", hRectWeight)
          .attr("x", 5)
          .attr("y", yScale(weightUnitConversion(weightKg)) - hRectWeight / 2)
          .attr("fill", "rgba(60,60,60,.8)")
        main
          .append("g")
          .attr("class", "rect chart-tooltip")
          .append("rect")
          .attr("width", wRectAge)
          .attr("height", hRectAge)
          .attr("x", xScale(ageWeeks) - wRectAge / 2)
          .attr("y", height - hRectAge - 5)
          .attr("fill", "rgba(60,60,60,.8)")
        main
          .append("line")
          .attr("class", "line chart-tooltip")
          .attr("x1", wRectWeight)
          .attr("y1", yScale(weightUnitConversion(weightKg)))
          .attr("x2", xScale(ageWeeks) - 9)
          .attr("y2", yScale(weightUnitConversion(weightKg)))
          .attr("stroke", "#5E5D5C")
          .style("stroke-dasharray", 4)
        main
          .append("line")
          .attr("class", "line chart-tooltip")
          .attr("x1", xScale(ageWeeks))
          .attr("y1", yScale(weightUnitConversion(weightKg)) + 9)
          .attr("x2", xScale(ageWeeks))
          .attr("y2", height - hRectAge)
          .attr("stroke", "#5E5D5C")
          .style("stroke-dasharray", 4)
        main
          .append("text")
          .attr("class", "text chart-tooltip")
          .attr("x", xScale(ageWeeks))
          .attr("y", height - hRectAge / 2)
          .attr("text-anchor", "middle")
          .style("font-size", `${tooltipFontSize}px`)
          .style("fill", "#fff")
          .text(ageWeeks.toFixed(1).replace(/\.0+$/, "") + " Weeks")
        main
          .append("text")
          .attr("class", "text chart-tooltip")
          .attr("x", wRectWeight / 2 + 5)
          .attr("y", yScale(weightUnitConversion(weightKg)) + 5)
          .attr("text-anchor", "middle")
          .style("font-size", `${tooltipFontSize}px`)
          .style("fill", "#fff")
          .text(
            weightUnitConversion(weightKg).toFixed(1).replace(/\.0+$/, "") +
              weightUnitLocal
          )
      })
      .on("touchend click", function (d) {
        d.preventDefault()
        let { ageWeeks, date, percentile, weightKg } = select(this).data()[0]
        // console.log(ageWeeks, date, percentile, weightKg)
        setUpdateAddedPetWeight(true)
        setWeightInitialValues({
          weight: weightUnitConversion(weightKg)
            .toFixed(1)
            .replace(/\.0+$/, "")
            .toString(),
          measurementDate: new Date(date),
        })
        setWeightShallowInitialValues({
          weight: weightUnitConversion(weightKg)
            .toFixed(1)
            .replace(/\.0+$/, "")
            .toString(),
          measurementDate: new Date(date),
        })
        setShowModalWeight(true)
      })
      .on("mouseout", removeTooltip)

    // Creation of points
    let highAgeWeeks, currentAgeWeeks
    if (
      percentiles.state.weights.weights.length !== 0 &&
      sortedFilteredData.length !== 0
    ) {
      highAgeWeeks = sortedFilteredData[0].ageWeeks
    }

    // console.log(highAgeWeeks)

    circles
      .attr("r", rCircle)
      .attr("cx", d => xScale(d.ageWeeks))
      .attr("cy", d => yScale(weightUnitConversion(d.weightKg)))
      .attr("class", d => {
        currentAgeWeeks = d.ageWeeks
        if (highAgeWeeks === currentAgeWeeks) {
          return "data-circle current"
        } else {
          return "data-circle"
        }
      })

    main.on("touchend", removeTooltip)

    // Remove tooltip
    function removeTooltip() {
      main.selectAll(".chart-tooltip").style("opacity", 0).remove()
    }
  }, [data, dimensions, weightUnitLocal])
  return (
    <>
      <div id="svgWrapper" ref={svgWrapperRef}>
        <svg ref={svgRef}></svg>
      </div>
    </>
  )
}

export default React.memo(GrowthChart)
