import React, { useState, useContext } from "react"
import DatePicker from "react-datepicker"
import { subDays } from "date-fns"
import moment from "moment"

import { Formik, Form, Field } from "formik"
import { ConnectedFocusError } from "focus-formik-error"
import * as Yup from "yup"
import Modal from "react-bootstrap/Modal"
import axios from "axios"

import {
  LoadingContext,
  LoginProfileDataContext,
  FetchDataContext,
  SnackbarContext,
  TabRenderContext,
} from "../app"
import {
  CurrentPetDataContext,
  UndoContext,
  UndoCloseContext,
} from "../templates/homepage/homepage"

function ModalAddNeutering(props) {
  const loadingContext = useContext(LoadingContext)
  const fetchDataContext = useContext(FetchDataContext)
  const loginProfileDataContext = useContext(LoginProfileDataContext)
  const currentPetDataContext = useContext(CurrentPetDataContext)
  const snackbarContext = useContext(SnackbarContext)

  const tabRenderContext = useContext(TabRenderContext)
  const undoContext = useContext(UndoContext)
  const undoCloseContext = useContext(UndoCloseContext)

  const initialValues = {
    neuteringDate: new Date(),
  }

  const validationSchema = Yup.object({
    neuteringDate: Yup.date().required("Required").nullable(),
  })

  const [data, setData] = useState(initialValues)

  const handleSubmit = values => {
    loadingContext.setLoading(true)

    props.setAddedNeuteringValue({
      neuter_date: values.neuteringDate,
      prev_values: {
        neuter_date: currentPetDataContext.state.neuter_date,
      },
    })

    const dataToSend = {
      neuter_date: moment(values.neuteringDate).format("YYYY-MM-DD"),
    }

    var config = {
      method: "patch",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/update-pet?_queryFilter1=mail eq ${loginProfileDataContext.state.owner.email}&_queryFilter2=_id eq ${loginProfileDataContext.state.owner._id}&_queryFilter3=petID eq ${currentPetDataContext.state.petID}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)
        fetchDataContext()
        props.onHide()
        undoContext.setState(true)
        undoCloseContext.setState(true)
        props.setAddedWeight({
          weight: "",
          weeks: "",
          confidenceMessage: { type: "", text: "" },
        })
        props.setAddedNeutering(
          moment(values.neuteringDate).format("D MMM YYYY")
        )

        snackbarContext.setState({
          classes: "alert-light icon-info",
          content:
            "You have added a neutering event to your pet's profile. Please be aware that neutering can cause non-standard weight deviation, which is acceptable for events like this.",
        })
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
        props.onHide()
      })
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        centered
        animation
        scrollable
        backdrop="static"
        className="neutering"
        onEntered={() => {
          tabRenderContext.setState(true)
          undoCloseContext.setState(false)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6" className="text-mars-blue">
            Add neutering event
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="caption text-mars-blue">
            All fields marked with an asterisk (
            <span className="text-mars-red">*</span>) are required
          </p>
          <Formik
            initialValues={data}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {formik => {
              return (
                <>
                  <Form id="myform" autoComplete="off">
                    <ConnectedFocusError />

                    <div
                      className={`form-input-hldr ${
                        formik.touched.neuteringDate &&
                        formik.errors.neuteringDate
                          ? "input-error"
                          : null
                      }`}
                    >
                      <label htmlFor="neuteringDate" className="form-label">
                        Add neutering date*
                      </label>
                      <Field name="neuteringDate">
                        {({ form, field }) => {
                          const { setFieldValue, setFieldTouched } = form
                          const { value } = field

                          return (
                            <DatePicker
                              id="neuteringDate"
                              className="form-control"
                              autoComplete="off"
                              dateFormat="dd/MM/yyyy"
                              {...field}
                              selected={value}
                              calendarStartDay={1}
                              minDate={subDays(
                                new Date(currentPetDataContext.state.dob),
                                -1
                              )}
                              maxDate={new Date()}
                              placeholderText="DD/MM/YYYY"
                              onChange={val => {
                                setFieldValue("neuteringDate", val)
                              }}
                              // onBlur={() => {
                              //   setFieldTouched("neuteringDate")
                              // }}
                              popperClassName="app-datepicker"
                              popperPlacement="bottom-end"
                              formatWeekDay={nameOfDay =>
                                nameOfDay.substr(0, 3)
                              }
                            />
                          )
                        }}
                      </Field>
                    </div>
                  </Form>
                </>
              )
            }}
          </Formik>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-outline-mars-blue"
            onClick={props.onHide}
          >
            Close
          </button>

          <button type="submit" className="btn btn-mars-blue" form="myform">
            Add neutering event
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalAddNeutering
