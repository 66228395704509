import React, { useContext } from "react"
import Modal from "react-bootstrap/Modal"

import axios from "axios"

import {
  LoadingContext,
  IsLoginContext,
  FetchDataContext,
  LoginProfileDataContext,
  ModalContext,
  TabRenderContext,
} from "../app"
import { CurrentPetDataContext } from "../templates/homepage/homepage"

function ModalRemovePetsProfile(props) {
  const loadingContext = useContext(LoadingContext)
  const loginProfileDataContext = useContext(LoginProfileDataContext)
  const modalContext = useContext(ModalContext)
  const isLoginContext = useContext(IsLoginContext)
  const fetchDataContext = useContext(FetchDataContext)
  const currentPetDataContext = useContext(CurrentPetDataContext)
  const tabRenderContext = useContext(TabRenderContext)

  const makeRemovePetsProfileRequest = () => {
    loadingContext.setLoading(true)
    var config = {
      method: "delete",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/delete-pet?_queryFilter1=mail eq ${loginProfileDataContext.state.owner.email}&_queryFilter2=_id eq ${loginProfileDataContext.state.owner._id}&_queryFilter3=petID eq ${currentPetDataContext.state.petID}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)
        if (response.data.pet_deleted) {
          fetchDataContext()
        }
        modalContext.dispatch("hideRemovePetsProfileModal")
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
      })
  }

  return (
    <>
      <Modal
        {...props}
        size="lg"
        centered
        animation
        scrollable
        className="remove-user-profile bg-image-none"
        backdrop="static"
        onEntered={() => {
          tabRenderContext.setState(false)
        }}
      >
        <figure class="text-center position-relative">
          <img className="img-fluid" src="../remove-pet-bg.png" alt="" />
          {currentPetDataContext.state.species === "dog" &&
          currentPetDataContext.state.breed !== null ? (
            <img
              src={`./puppy-icons/${currentPetDataContext.state.breed
                .split(" ")
                .join("-")
                .toLowerCase()}.svg`}
              onError={e => {
                e.target.src = "./puppy-icons/puppy.svg"
              }}
              alt={`${currentPetDataContext.state.breed}`}
              className="position-absolute remove-breed-img"
            />
          ) : currentPetDataContext.state.species === "dog" &&
            currentPetDataContext.state.breed === null ? (
            <img
              src={`./puppy-icons/puppy.svg`}
              alt="puppy"
              className="position-absolute remove-breed-img"
            />
          ) : (
            <img
              src={`./puppy-icons/kitten.svg`}
              alt={`${currentPetDataContext.state.breed}`}
              className="position-absolute remove-breed-img"
            />
          )}
        </figure>

        <Modal.Body>
          <p className="subtitle-1 text-mars-gray">Remove pet’s profile</p>
          <div className="body-2">
            Are you sure you want to remove your pet's profile? This action
            cannot be undone.
          </div>
          <div className="d-grid">
            <button
              type="button"
              className="btn btn-outline-mars-blue"
              onClick={() => {
                modalContext.dispatch("hideRemovePetsProfileModal")
                modalContext.dispatch("showUpdatePetsProfileModal")
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-text"
              onClick={() => {
                makeRemovePetsProfileRequest()
              }}
            >
              Remove
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ModalRemovePetsProfile
