import React, { useContext } from "react"
import Modal from "react-bootstrap/Modal"

import { ModalContext } from "../../app"

function DialogueUnsavedChanges({
  setUnSavedData,
  title,
  body,
  backBtnText,
  cancelBtnText,
  hideModal,
  hideLocalModal,
}) {
  const modalContext = useContext(ModalContext)
  return (
    <>
      <figure>
        <img
          className="img-fluid"
          src="../remove-pet.svg"
          alt=""
        />
      </figure>
      <Modal.Body>
        <p className="subtitle-1 text-mars-gray">{title}</p>
        <div className="body-2">{body}</div>
        <div className="d-grid">
          <button
            type="button"
            className="btn btn-outline-mars-blue"
            onClick={() => {
              setUnSavedData(false)
            }}
          >
            {backBtnText}
          </button>
          <button
            type="button"
            className="btn btn-text"
            onClick={() => {
              if (hideLocalModal) {
                hideLocalModal()
              } else {
                modalContext.dispatch(hideModal)
              }
            }}
          >
            {cancelBtnText}
          </button>
        </div>
      </Modal.Body>
    </>
  )
}

export default DialogueUnsavedChanges
