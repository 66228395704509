import React, { useState, useContext } from "react"
import DatePicker from "react-datepicker"
import moment from "moment"
import { isEqual } from "lodash"

import { Formik, Form, Field, ErrorMessage } from "formik"
import { ConnectedFocusError } from "focus-formik-error"
import * as Yup from "yup"
import Modal from "react-bootstrap/Modal"
import CloseButton from "react-bootstrap/CloseButton"
import Accordion from "react-bootstrap/Accordion"
import axios from "axios"

import {
  LoadingContext,
  FetchDataContext,
  LoginProfileDataContext,
  SnackbarContext,
  GrowthChartSnackbarContext,
  TabRenderContext,
  AddedPetIdContext,
} from "../app"
import {
  CurrentPetDataContext,
  UndoContext,
  UndoCloseContext,
  ToggleGrowthGuidanceSnackbarContext,
} from "../templates/homepage/homepage"

import DialogueUnsavedChanges from "./helpers/dialogue-unsaved-changes"

const initialValues = {
  weight: "",
  measurementDate: new Date(),
}

function ModalUpdatePetsWeight(props) {
  const loadingContext = useContext(LoadingContext)
  const fetchDataContext = useContext(FetchDataContext)
  const loginProfileDataContext = useContext(LoginProfileDataContext)
  const currentPetDataContext = useContext(CurrentPetDataContext)
  const snackbarContext = useContext(SnackbarContext)
  const growthChartSnackbarContext = useContext(GrowthChartSnackbarContext)
  const toggleGrowthGuidanceSnackbarContext = useContext(
    ToggleGrowthGuidanceSnackbarContext
  )

  const tabRenderContext = useContext(TabRenderContext)
  const addedPetIdContext = useContext(AddedPetIdContext)
  const undoContext = useContext(UndoContext)
  const undoCloseContext = useContext(UndoCloseContext)

  const validationSchema = Yup.object({
    // weight: Yup.string().required("Required"),
    weight: Yup.number()
      .typeError("Weight should be in number and '.' format")
      .required("Weight is required field")
      .min(
        currentPetDataContext.state.species == "dog" ? 1 : 0.1,
        // 0.1,
        `Oh no - you're trying to add that your pet weighs nothing! Please check the weight you have entered and try again.`
      )
      .max(
        props.addedWeightLimit,
        `Oh no - this weight is greater than the expected adult weight of your pet. If the weight is correct, please go to your pet's profile and select a different growth chart before adding the weight again.`
      ),
    measurementDate: Yup.date().required("Required").nullable(),
  })
  const [data, setData] = useState(initialValues)
  const [unSavedData, setUnSavedData] = useState(false)

  const isEqualData = () => {
    if (props.updateAddedPetWeight) {
      if (
        isEqual(props.weightShallowInitialValues, props.weightInitialValues)
      ) {
        props.onHide()
      } else {
        setUnSavedData(true)
      }
    } else {
      if (isEqual(data, initialValues)) {
        props.onHide()
      } else {
        setUnSavedData(true)
      }
    }
  }

  const addWeightRequest = formData => {
    const birthday = moment(currentPetDataContext.state.dob)
    const selectedDate = moment(formData.measurementDate)
    let diff = moment.duration(selectedDate.diff(birthday))
    let weeks = diff.asWeeks()
    weeks = weeks.toFixed(1)

    loadingContext.setLoading(true)
    let weightConverted

    if (props.weightUnitLocal === "lbs") {
      weightConverted = formData.weight / 2.20462
    } else {
      weightConverted = formData.weight
    }

    // growthChartSnackbarContext.setState({
    //   classes: "",
    //   content: "",
    // })

    const dataToSend = {
      petID: currentPetDataContext.state.petID,
      weight_kg: weightConverted,
      date: moment(formData.measurementDate).format("YYYY-MM-DD"),
    }

    var config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/add-weight?_queryFilter1=mail eq ${loginProfileDataContext.state.owner.email}&_queryFilter2=_id eq ${loginProfileDataContext.state.owner._id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)
        props.onHide()
        if (props.updateAddedPetWeight) {
          undoContext.setState(false)
          undoCloseContext.setState(false)
        } else {
          undoContext.setState(true)
          undoCloseContext.setState(true)
        }
        props.setAddedNeutering("")
        props.setAddedWeight({
          weight: response.data.req_data.weight_kg,
          weeks: response.data.req_data.getAgeWeeks_suc,
          confidenceMessage:
            response.data.weights[0].weights.recommendation.confidenceMessage,
        })
        props.setWeightInitialValues(initialValues)
        props.setAddedWeightValues({
          petID: response.data.req_data.petID,
          weight_kg: response.data.req_data.weight_kg,
          date: response.data.req_data.date,
        })
        addedPetIdContext.setState(response.data.req_data.petID)
        toggleGrowthGuidanceSnackbarContext.setState(false)
        fetchDataContext()
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
      })
  }

  const deleteWeightRequest = () => {
    loadingContext.setLoading(true)
    undoCloseContext.setState(false)
    const dataToSend = {
      petID: currentPetDataContext.state.petID,
      weight_kg: props.weightInitialValues.weight,
      date: moment(props.weightInitialValues.measurementDate).format(
        "YYYY-MM-DD"
      ),
    }
    var config = {
      method: "delete",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/delete-weight?_queryFilter1=mail eq ${loginProfileDataContext.state.owner.email}&_queryFilter2=_id eq ${loginProfileDataContext.state.owner._id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)
        props.onHide()

        fetchDataContext()

        snackbarContext.setState({
          classes: "alert-success icon-success",
          content: `weight ${response.data.req_data.weight_kg} kg on ${moment(
            response.data.req_data.date
          ).format("DD MMM YYYY")} has been deleted.`,
        })
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
      })
  }

  const handleSubmit = values => {
    if (props.updateAddedPetWeight) {
      if (
        isEqual(props.weightShallowInitialValues, props.weightInitialValues)
      ) {
        props.onHide()
        snackbarContext.setState({
          classes: "alert-danger icon-failed",
          content: "There are no changes to submit",
        })
      } else {
        addWeightRequest(values)
      }
    } else {
      addWeightRequest(values)
    }
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        centered
        animation
        scrollable
        onEntered={() => {
          tabRenderContext.setState(true)
        }}
        onExited={() => {
          setUnSavedData(false)
          setData(initialValues)
          props.setUpdateAddedPetWeight(false)
          props.setWeightInitialValues(initialValues)
        }}
        className={`update-weight ${
          unSavedData && "remove-user-profile bg-image-none"
        }`}
        backdrop="static"
      >
        {!unSavedData ? (
          <>
            <Modal.Header>
              <Modal.Title as="h6" className="text-mars-blue">
                Add new weight
              </Modal.Title>
              <CloseButton
                onClick={() => {
                  isEqualData()
                }}
              />
            </Modal.Header>
            <Modal.Body>
              <p className="caption text-mars-blue">
                All fields marked with an asterisk (
                <span className="text-mars-red">*</span>) are required
              </p>
              <Formik
                initialValues={
                  props.updateAddedPetWeight ? props.weightInitialValues : data
                }
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {formik => {

                  if (props.updateAddedPetWeight) {
                    props.setWeightInitialValues(formik.values)
                  } else {
                    setData(formik.values)
                  }

                  return (
                    <>
                      <Form id="myform" autoComplete="off">
                        <ConnectedFocusError />
                        <div
                          className={`form-input-hldr ${
                            formik.touched.weight && formik.errors.weight
                              ? "input-error"
                              : null
                          }`}
                        >
                          <label htmlFor="weight" className="form-label">
                            Weight ({props.weightUnitLocal})*
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            id="weight"
                            name="weight"
                            placeholder={`Weight (${props.weightUnitLocal})`}
                          />
                          {/* {formik.errors.weight &&
                          formik.values.weight !== "" ? (
                            <p className="caption text-mars-red">
                              The weight you are trying to enter exceeding the
                              Pet's adult weight, please select appropriate
                              weight range in Pet's profile and try adding the
                              weight again.
                            </p>
                          ) : null} */}
                          <ErrorMessage name="weight">
                            {errorMsg => (
                              <p className="caption text-mars-red">
                                {errorMsg}
                              </p>
                            )}
                          </ErrorMessage>
                        </div>

                        <div
                          className={`form-input-hldr ${
                            formik.touched.measurementDate &&
                            formik.errors.measurementDate
                              ? "input-error"
                              : null
                          }`}
                        >
                          <label
                            htmlFor="measurementDate"
                            className="form-label"
                          >
                            Measurement date*
                          </label>
                          <Field name="measurementDate">
                            {({ form, field }) => {
                              const { setFieldValue, setFieldTouched } = form
                              const { value } = field

                              return (
                                <DatePicker
                                  id="measurementDate"
                                  className="form-control"
                                  autoComplete="off"
                                  dateFormat="dd/MM/yyyy"
                                  {...field}
                                  selected={value}
                                  calendarStartDay={1}
                                  minDate={
                                    new Date(currentPetDataContext.state.dob)
                                  }
                                  maxDate={new Date()}
                                  placeholderText="DD/MM/YYYY"
                                  onChange={val => {
                                    setFieldValue("measurementDate", val)
                                  }}
                                  // onBlur={() => {
                                  //   setFieldTouched("measurementDate")
                                  // }}
                                  popperClassName="app-datepicker"
                                  popperPlacement="bottom-end"
                                  formatWeekDay={nameOfDay =>
                                    nameOfDay.substr(0, 3)
                                  }
                                  disabled={props.updateAddedPetWeight}
                                />
                              )
                            }}
                          </Field>
                        </div>
                      </Form>
                    </>
                  )
                }}
              </Formik>

              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header as="div">
                    <div className="subtitle-2 d-flex align-items-center">
                      <figure className="mb-0 plus">
                        <img
                          className="img-fluid d-block"
                          src="../../../icons/icon-accord-plus.png"
                          alt=""
                        />
                      </figure>
                      <figure className="mb-0 minus">
                        <img
                          className="img-fluid d-block"
                          src="../../../icons/icon-accord-minus.png"
                          alt=""
                        />
                      </figure>
                      <span>
                        Top tips for measuring your{" "}
                        {currentPetDataContext.state.species == "dog"
                          ? "puppy's"
                          : "kitten's"}{" "}
                        weight
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    {currentPetDataContext.state.species == "dog" && (
                      <>
                        <p className="caption">
                          If your puppy isn't too heavy to lift and hold, you
                          can weigh them at home using regular digital scales.
                          First weigh yourself, and then pick up your pup and
                          weigh the both of you. Subtract your weight, from your
                          combined weight and you have the weight of your puppy.
                          If your pup is too heavy, or doesn't like to be held,
                          you may need to use the dog scales at your local
                          veterinary clinic.
                        </p>
                        <p className="caption mb-0">
                          Top Tip: Try to use the same scales each time you
                          weigh your puppy. Don't forget to factor in how big
                          they're going to grow!
                        </p>
                      </>
                    )}
                    {currentPetDataContext.state.species == "cat" && (
                      <>
                        <p className="caption">
                          You can weigh your kitten at home using regular
                          digital scales. You may want to put them in something
                          e.g., a carrier or bowl to keep them still when
                          weighing. If doing this don’t forget to subtract the
                          weight of what you’re using from the combined weight
                          to get the accurate weight of your kitty. Check your
                          scales go up in small (e.g., 1 gram) increments to
                          accurately track your kitten’s growth.
                        </p>

                        <p className="caption mb-0">
                          Top Tip: Try to use the same scales each time you
                          weigh your kitten. Make sure you factor in how big
                          they're going to grow, or how wriggly they may get!
                        </p>
                      </>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              {props.updateAddedPetWeight && (
                <>
                  <div
                    className="d-inline-flex align-items-center btn-text-icon"
                    onClick={deleteWeightRequest}
                  >
                    <figure className="flex-shrink-0 mb-0">
                      <img
                        className="img-fluid"
                        src="../../../icons/icon-trash.svg"
                        alt=""
                      />
                    </figure>
                    <span className="d-inline-block btn-text">
                      Remove weight
                    </span>
                  </div>
                </>
              )}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-outline-mars-blue"
                onClick={() => {
                  isEqualData()
                }}
              >
                Close
              </button>

              <button type="submit" className="btn btn-mars-blue" form="myform">
                Add weight
              </button>
            </Modal.Footer>
          </>
        ) : (
          <DialogueUnsavedChanges
            setUnSavedData={setUnSavedData}
            title={"You have unsaved changes."}
            body={`Oh no, if you leave now your changes will be lost.`}
            backBtnText={`Back to "Add new weight"`}
            cancelBtnText={`Leave "Add new weight"`}
            hideLocalModal={props.onHide}
          />
        )}
      </Modal>
    </>
  )
}

export default React.memo(ModalUpdatePetsWeight)
