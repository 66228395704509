import React, { useState, useContext, useEffect } from "react"
import Modal from "react-bootstrap/Modal"
import CloseButton from "react-bootstrap/CloseButton"
import axios from "axios"
import { isEqual } from "lodash"
import moment from "moment"

import {
  LoadingContext,
  LoginProfileDataContext,
  FetchDataContext,
  ModalContext,
  SnackbarContext,
  TabRenderContext,
  // IsLoginContext,
} from "../app"
import {
  CurrentPetDataContext,
  UndoCloseContext,
} from "../templates/homepage/homepage"

import AddMyPet from "../forms/form-signup/form-add-my-pet"
import DialogueUnsavedChanges from "./helpers/dialogue-unsaved-changes"

function ModalUpdatePetsProfile(props) {
  const loadingContext = useContext(LoadingContext)
  const modalContext = useContext(ModalContext)
  const loginProfileDataContext = useContext(LoginProfileDataContext)
  const fetchDataContext = useContext(FetchDataContext)
  const snackbarContext = useContext(SnackbarContext)
  const tabRenderContext = useContext(TabRenderContext)
  const undoCloseContext = useContext(UndoCloseContext)
  // const isLoginContext = useContext(IsLoginContext)

  const currentPetDataContext = useContext(CurrentPetDataContext)
  const currentPetData = {
    petName: currentPetDataContext.state.pet_name,
    birthDate: new Date(currentPetDataContext.state.dob),
    knowPetsBreed: currentPetDataContext.state.do_you_know_your_pets_breed,
    petsBreed: currentPetDataContext.state.breed === null ? "" : currentPetDataContext.state.breed,
    mixedBreed:
      currentPetDataContext.state.mixed_breed === "yes" ? true : false,
    petsAdultWeight: currentPetDataContext.state.breed_size,
    petsGender: currentPetDataContext.state.sex,
    weightUnitPet: loginProfileDataContext.state.owner.weight_unit,
    id: currentPetDataContext.state._id,
    species: currentPetDataContext.state.species,
  }

  // const [dobError, setDobError] = useState({})

  const [unSavedData, setUnSavedData] = useState(false);
  const [data, setData] = useState(currentPetData);
  const [shallowData, setShallowData] = useState(false);
  // const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);

  const updatePetsProfile = true

  const isEqualData = () => {
    setShallowData(true)
    if (isEqual(currentPetData, data)) {
      props.onHide()
    } else {
      setUnSavedData(true)
    }
  }

  const makeUpdatePetRequest = (formData, dobRef) => {

    loadingContext.setLoading(true)
    let filteredData = {}

    if (currentPetData.petName !== formData.petName) {
      filteredData.pet_name = formData.petName
    }
    if (currentPetData.birthDate !== formData.birthDate) {
      filteredData.dob = moment(formData.birthDate).format("YYYY-MM-DD")
    }

    if (currentPetData.petsBreed !== formData.petsBreed) {
      filteredData.breed = formData.petsBreed
    }

    // if (currentPetData.mixedBreed !== formData.mixedBreed) {
    //   filteredData.mixed_breed = formData.mixedBreed ? "yes" : null
    //   filteredData.breed = formData.mixedBreed ? null : formData.petsBreed
    // }
    if (currentPetData.mixedBreed !== formData.mixedBreed) {
      filteredData.mixed_breed = formData.mixedBreed ? "yes" : null
      if(currentPetData.species === "cat") {
        filteredData.breed = formData.mixedBreed ? null : formData.petsBreed
      }
    }
    // if (formData.mixedBreed) {
    //   filteredData.mixed_breed = "yes"
    //   filteredData.breed = null
    // } else {
    //   filteredData.mixed_breed = null
    //   filteredData.breed = formData.petsBreed
    // }

    if (currentPetData.petsAdultWeight !== formData.petsAdultWeight) {
      filteredData.breed_size = formData.petsAdultWeight
    }
    if (currentPetData.petsGender !== formData.petsGender) {
      filteredData.sex = formData.petsGender
    }

    const dataToSend = filteredData

    var config = {
      method: "patch",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/update-pet?_queryFilter1=mail eq ${loginProfileDataContext.state.owner.email}&_queryFilter2=_id eq ${loginProfileDataContext.state.owner._id}&_queryFilter3=petID eq ${currentPetDataContext.state.petID}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        loadingContext.setLoading(false)
        modalContext.dispatch("hideUpdatePetsProfileModal")
        fetchDataContext()
        // setDobError(response.data)
        snackbarContext.setState({
          classes: "alert-success icon-success",
          content: "Pet's profile was updated successfully",
        })
        // if (
        //   response.data.error === true &&
        //   response.data.message.indexOf("dob") !== -1
        // ) {
        //   let message = response.data.message.split("'")
        //   setDobError(message[3])
        //   dobRef.current.focus()
        // }
      })
      .catch((error) => {
        loadingContext.setLoading(false);
        modalContext.dispatch("hideUpdatePetsProfileModal");
      });
  };

  // useEffect(() => {
  //   if (
  //     data.petName === "" ||
  //     data.birthDate === "" ||
  //     data.birthDate === null
  //     // data.birthDate === "Invalid Date"
  //   ) {
  //     setSubmitBtnDisabled(true);
  //   } else {
  //     setSubmitBtnDisabled(false);
  //   }
  // }, [data]);

  return (
    <Modal
      {...props}
      size="lg"
      centered
      animation
      scrollable
      onEntered={() => {
        tabRenderContext.setState(false)
        undoCloseContext.setState(false)
      }}
      onExited={() => {
        setData(currentPetData)
        setUnSavedData(false)
        setShallowData(false)
      }}
      backdrop="static"
      className={`${unSavedData && "remove-user-profile bg-image-none"}`}
    >
      {!unSavedData ? (
        <>
          <Modal.Header>
            <Modal.Title as="h6" className="text-mars-blue">
              Pet’s profile
            </Modal.Title>
            <CloseButton
              onClick={() => {
                isEqualData()
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <AddMyPet
              data={shallowData ? data : currentPetData}
              // data={data}
              setData={setData}
              updatePetsProfile={updatePetsProfile}
              makeUpdatePetRequest={makeUpdatePetRequest}
              // dobError={dobError}
              // setDobError={setDobError}
          />
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-outline-mars-blue"
              onClick={() => {
                isEqualData()
              }}
            >
              Close
            </button>

            <button
              type="submit"
              className="btn btn-mars-blue"
              form="myform"
            >
              Submit
            </button>
          </Modal.Footer>
        </>
      ) : (
        <DialogueUnsavedChanges
          setUnSavedData={setUnSavedData}
          title={"Oh no - you have unsaved changes."}
          body={
            "If you leave your pet's profile all the changes you made will be lost."
          }
          backBtnText={"Back To Profile"}
          cancelBtnText={"Leave Profile"}
          hideModal={"hideUpdatePetsProfileModal"}
        />
      )}
    </Modal>
  )
}

export default ModalUpdatePetsProfile
