import React, { useEffect, useState, useContext } from "react"
import OtpInput from "react-otp-input"

import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import axios from "axios"

import {
  LoadingContext,
  IsNewUserRequestContext,
  OTPRequestContext,
  ResetPasswordContext,
} from "../../app"

function OTPBox(props) {
  // Password Show and Hide Toggle
  const [passwordShown, setPasswordShown] = useState(false)
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false)

  // OTP Status Controls
  const [OTPValue, setOTPValue] = useState("")
  const [OTPMatched, setOTPMatched] = useState(false)
  const [OTPNotMatched, setOTPNotMatched] = useState(false)

  // OTP Timer Controls
  const [OTPTimerStart, setOTPTimerStart] = useState(true)
  const [OTPTimerDuration, setOTPTimerDuration] = useState(30)
  const [OTPResendEnable, setOTPResendEnable] = useState(true)

  const [signupOTPMessage, setSignupOTPMessage] = useState(
    "One time passcode has been sent to you email address. Please check your spam folder."
  )

  const isNewUserRequestContext = useContext(IsNewUserRequestContext)
  const otpRequestContext = useContext(OTPRequestContext)
  const resetPasswordContext = useContext(ResetPasswordContext)
  const loadingContext = useContext(LoadingContext)

  let validationSchema, initialValues
  if (resetPasswordContext.state) {
    initialValues = {
      newPassword: "",
      confirmNewPassword: "",
    }

    validationSchema = Yup.object({
      newPassword: Yup.string().required("Required"),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Password doesn't match")
        .required("Required"),
    })
  }

  const makeResendOtpRequest = () => {
    loadingContext.setLoading(true)

    const dataToSend = {
      email: props.data.emailAddress,
      password: props.data.password,
      first_name: props.data.firstName,
      last_name: props.data.lastName,
    }

    var config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/register-owner`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)
        setSignupOTPMessage(
          "Oops – having trouble receiving the passcode? Please check your spam folder. If it is not here, we may be having a problem verifying this email domain. Please try a different email address."
        )
        otpRequestContext.dispatch({
          type: "OTP_SENT_SUCCESS",
          authId: response.data.authId,
          ownerEnterCookie: response.data.owner_enter_cookie,
        })
        setOTPTimerStart(true)
        setOTPValue("")
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
      })
  }
  const handleSubmit = values => {
    if (props.forgotPassword) {
      props.makeResetPasswordRequest(values)
    } else {
      props.next(values, false, true)
    }
  }

  useEffect(() => {
    if (OTPValue.length === 6) {
      if (props.signupForm) {
        props.makeOwnerOtpVerifyRequest(
          OTPValue,
          setOTPMatched,
          setOTPNotMatched,
          setOTPResendEnable,
          setOTPTimerStart,
          setOTPTimerDuration
        )
      } else if (props.forgotPassword && !props.oldOtp && !props.newOtp) {
        props.makeVerifyOtpRequest(
          OTPValue,
          setOTPMatched,
          setOTPNotMatched,
          setOTPResendEnable,
          setOTPTimerStart,
          setOTPTimerDuration
        )
      } else if (props.forgotPassword && props.oldOtp && !props.newOtp) {
        props.makeVerifyOldOtpRequest(
          OTPValue,
          setOTPValue,
          setOTPMatched,
          setOTPNotMatched,
          setOTPResendEnable,
          setOTPTimerStart,
          setOTPTimerDuration
        )
      } else if (props.forgotPassword && !props.oldOtp && props.newOtp) {
        props.makeVerifyNewOtpRequest(
          OTPValue,
          setOTPValue,
          setOTPMatched,
          setOTPNotMatched,
          setOTPResendEnable,
          setOTPTimerStart,
          setOTPTimerDuration
        )
      }
    }
  }, [OTPValue])

  useEffect(() => {
    let OTPInterval
    if (OTPTimerStart) {
      setOTPResendEnable(false)
      OTPInterval = setInterval(() => {
        if (OTPTimerDuration > 0) {
          setOTPTimerDuration(prev => prev - 1)
        } else {
          clearInterval(OTPInterval)
          setOTPTimerDuration(30)
          setOTPTimerStart(false)
          setOTPResendEnable(true)
        }
      }, 1000)
    }
    return () => {
      clearInterval(OTPInterval)
    }
  }, [OTPTimerStart, OTPTimerDuration])

  return (
    <>
      <p className="body-2 mb-0">{props.title}</p>
      <Formik
        initialValues={resetPasswordContext.state ? initialValues : props.data}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {formik => {
          return (
            <>
              <Form id="myform" autoComplete="off">
                <div className="otp-container">
                  <figure className="mb-0">
                    <img
                      className="img-fluid"
                      src="../../../banner-dogs-otp.png"
                      alt=""
                    />
                  </figure>
                  <div className="otp-hldr">
                    {props.signupForm ? (
                      <p className="caption">{signupOTPMessage}</p>
                    ) : (
                      <p className="caption">
                        A one time passcode was sent to your email address:{" "}
                        <strong>{props.emailAddress}</strong>. Please check your
                        email and enter the passcode into the following field.
                      </p>
                    )}

                    <hr className="divider" />

                    <OtpInput
                      value={OTPValue}
                      onChange={otp => setOTPValue(otp)}
                      numInputs={6}
                      containerStyle={`d-flex justify-content-between otp-hldr_input ${
                        OTPNotMatched ? "otp-error" : ""
                      }`}
                      isDisabled={
                        (!OTPNotMatched && props.OTPError !== "") ||
                        OTPMatched ||
                        isNewUserRequestContext.state.isOTPAlreadyVerified
                      }
                      isInputNum
                    />

                    <p className="body-2 mb-0">6 digits code</p>
                    {OTPMatched ||
                    isNewUserRequestContext.state.isOTPAlreadyVerified ? (
                      <div className="body-2 status success">
                        <span className="icon d-inline-block"></span>
                        {isNewUserRequestContext.state.isOTPAlreadyVerified
                          ? "Already Verified"
                          : "Verified"}
                      </div>
                    ) : null}

                    {OTPNotMatched && (
                      <div className="body-2 status error">
                        <span className="icon d-inline-block"></span>Entered
                        one-time passcode doesn't match. Please try to enter it
                        one more time or click “Resent passcode” button.
                      </div>
                    )}
                    {props.OTPError !== "" && (
                      <div className="body-2 status error">
                        <span className="icon d-inline-block"></span>
                        {props.OTPError}
                      </div>
                    )}
                  </div>

                  {OTPTimerStart || (OTPTimerStart && OTPNotMatched) ? (
                    <div className="request-passcode">
                      Request passcode in <span>{OTPTimerDuration}</span> sec
                    </div>
                  ) : null}

                  <span
                    // className={`d-inline-block btn-text ${
                    //   !OTPNotMatched || startOTPTimer ? "disabled" : ""
                    // }`}
                    className={`d-inline-block btn-text ${
                      OTPResendEnable ? "" : "disabled"
                    }`}
                    onClick={() => {
                      if (
                        props.forgotPassword &&
                        !props.oldOtp &&
                        !props.newOtp
                      ) {
                        props.makeForgotPasswordRequest(props.emailAddress)
                        setOTPTimerStart(true)
                        setOTPValue("")
                      } else if (
                        props.forgotPassword &&
                        props.oldOtp &&
                        !props.newOtp
                      ) {
                        props.makeUpdateOwnerRequest(props.data)
                        setOTPTimerStart(true)
                        setOTPValue("")
                      } else if (
                        props.forgotPassword &&
                        !props.oldOtp &&
                        props.newOtp
                      ) {
                        props.makeResendNewOtpRequest(
                          OTPValue,
                          setOTPValue,
                          setOTPMatched,
                          setOTPNotMatched
                        )
                        setOTPTimerStart(true)
                        setOTPValue("")
                      } else if (props.signupForm) {
                        makeResendOtpRequest()
                      }
                    }}
                  >
                    Resend Passcode
                  </span>
                </div>

                {OTPMatched && resetPasswordContext.state ? (
                  <>
                    <div className="form-input-group-hldr">
                      <div
                        className={`form-input-hldr ${
                          (formik.touched.newPassword &&
                            formik.errors.newPassword) ||
                          props.passwordNotChanged
                            ? "input-error"
                            : null
                        }`}
                      >
                        <label htmlFor="newPassword" className="form-label">
                          New Password*
                        </label>
                        <div className="toggle-password-hldr">
                          <Field name="newPassword">
                            {({ form, field }) => {
                              const { setFieldValue, setFieldTouched } = form
                              const { value } = field
                              return (
                                <>
                                  <input
                                    type={passwordShown ? "text" : "password"}
                                    className="form-control"
                                    id="newPassword"
                                    placeholder="New Password"
                                    value={value}
                                    onChange={e => {
                                      setFieldValue(
                                        "newPassword",
                                        e.target.value
                                      )
                                      props.setPasswordNotChanged(false)
                                      otpRequestContext.dispatch({
                                        type: "PASSWORD_ERROR_RESET",
                                      })
                                    }}
                                    onBlur={() => {
                                      setFieldTouched("newPassword")
                                    }}
                                  />
                                  <span
                                    className={`toggle-password ${
                                      passwordShown ? "on" : ""
                                    }`}
                                    onClick={() =>
                                      setPasswordShown(!passwordShown)
                                    }
                                  ></span>
                                </>
                              )
                            }}
                          </Field>
                        </div>
                        {otpRequestContext.state.passwordPatternError !==
                          "" && (
                          <p className="caption text-mars-red">
                            {otpRequestContext.state.passwordPatternError}
                          </p>
                        )}
                      </div>

                      <div className="instructions">
                        <p className="caption">
                          Password requirements:
                          <span className="d-block">
                            {" "}
                            &#8226; &nbsp; At least 8 characters{" "}
                          </span>
                          <span className="d-block">
                            {" "}
                            &#8226; &nbsp; At least one uppercase and one
                            lowercase letter
                          </span>
                          <span className="d-block">
                            {" "}
                            &#8226; &nbsp; At least one number{" "}
                          </span>
                          <span className="d-block">
                            {" "}
                            &#8226; &nbsp; May not contain your first or last
                            name
                          </span>
                        </p>
                        <p className="caption mb-0">
                          If changing or resetting your password, your new
                          password should be different to your last 3 passwords
                        </p>
                      </div>
                      <div
                        className={`form-input-hldr ${
                          (formik.touched.confirmNewPassword &&
                            formik.errors.confirmNewPassword) ||
                          props.passwordNotChanged
                            ? "input-error"
                            : null
                        }`}
                      >
                        <label
                          htmlFor="confirmNewPassword"
                          className="form-label"
                        >
                          Confirm new password*
                        </label>
                        <div className="toggle-password-hldr">
                          <Field name="confirmNewPassword">
                            {({ form, field }) => {
                              const { setFieldValue, setFieldTouched } = form
                              const { value } = field
                              return (
                                <>
                                  <input
                                    type={
                                      confirmPasswordShown ? "text" : "password"
                                    }
                                    className="form-control"
                                    id="confirmNewPassword"
                                    placeholder="Confirm new Password"
                                    value={value}
                                    onChange={e => {
                                      setFieldValue(
                                        "confirmNewPassword",
                                        e.target.value
                                      )
                                      props.setPasswordNotChanged(false)
                                      otpRequestContext.dispatch({
                                        type: "PASSWORD_ERROR_RESET",
                                      })
                                    }}
                                    onBlur={() => {
                                      setFieldTouched("confirmNewPassword")
                                    }}
                                  />
                                  <span
                                    className={`toggle-password ${
                                      confirmPasswordShown ? "on" : ""
                                    }`}
                                    onClick={() =>
                                      setConfirmPasswordShown(
                                        !confirmPasswordShown
                                      )
                                    }
                                  ></span>
                                </>
                              )
                            }}
                          </Field>
                        </div>
                        {formik.errors.confirmNewPassword &&
                        formik.values.confirmNewPassword !== "" &&
                        formik.values.newPassword !==
                          formik.values.confirmNewPassword ? (
                          <p className="caption text-mars-red">
                            Password doesn't match
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : null}
              </Form>
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default OTPBox
