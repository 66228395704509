import React, { useContext } from "react"
import Modal from "react-bootstrap/Modal"

import axios from "axios"

import {
  LoadingContext,
  IsLoginContext,
  LoginProfileDataContext,
  ModalContext,
  TabRenderContext,
} from "../app"

function ModalRemoveUserProfile(props) {
  const loadingContext = useContext(LoadingContext)
  const loginProfileDataContext = useContext(LoginProfileDataContext)
  const modalContext = useContext(ModalContext)
  const isLoginContext = useContext(IsLoginContext)
  const tabRenderContext = useContext(TabRenderContext)

  const makeRemoveUserProfileRequest = () => {
    loadingContext.setLoading(true)
    var config = {
      method: "delete",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/delete-owner?_queryFilter1=mail eq ${loginProfileDataContext.state.owner.email}&_queryFilter2=_id eq ${loginProfileDataContext.state.owner._id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)
        loginProfileDataContext.setState({})
        isLoginContext.setIsLogin(false)
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
        // isLoginContext.setIsLogin(false)
      })
  }

  return (
    <>
      <Modal
        {...props}
        size="lg"
        centered
        animation
        scrollable
        className="remove-user-profile bg-image-none"
        backdrop="static"
        onEntered={() => {
          tabRenderContext.setState(false)
        }}
      >
        <figure>
          <img
            className="img-fluid"
            src="../../../remove-profile-banner.png"
            alt=""
          />
        </figure>
        <Modal.Body>
          <p className="subtitle-1 text-mars-gray">Remove user profile</p>
          <div className="body-2">
            Are you sure you want to remove your profile? This action cannot be
            undone.
          </div>
          <div className="d-grid">
            <button
              type="button"
              className="btn btn-outline-mars-blue"
              onClick={() => {
                modalContext.dispatch("hideRemoveUserProfileModal")
                modalContext.dispatch("showUpdateUserProfileModal")
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-text"
              onClick={() => {
                makeRemoveUserProfileRequest()
              }}
            >
              Remove
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ModalRemoveUserProfile
