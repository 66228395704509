import React, { useState, useContext, useEffect } from "react"

import { GrowthChartSnackbarContext } from "../app"
import {
  CurrentPetDataContext,
  ToggleConfidenceMessageSnackbarContext,
} from "../templates/homepage/homepage"

function ConfidenceMessageSnackbar() {
  const growthChartSnackbarContext = useContext(GrowthChartSnackbarContext)
  const currentPetDataContext = useContext(CurrentPetDataContext)
  // const toggleConfidenceMessageSnackbarContext = useContext(
  //   ToggleConfidenceMessageSnackbarContext
  // )

  let isConfidenceMessageRepeated =
    currentPetDataContext.state.weights.weights.length !== 0
      ? currentPetDataContext.state.weights.recommendation.confidenceMessage
          .isRepeated
      : false

  const [toggleSnackbar, setToggleSnackbar] = useState(
    isConfidenceMessageRepeated
  )

  const toggleSnackbarHandler = () => {
    // toggleConfidenceMessageSnackbarContext.setState(
    //   !toggleConfidenceMessageSnackbarContext.state
    // )
    setToggleSnackbar(!toggleSnackbar)
  }

  useEffect(() => {
    setToggleSnackbar(isConfidenceMessageRepeated)
  }, [growthChartSnackbarContext])

  return (
    <>
      {growthChartSnackbarContext.state.confidenceMessage !== "" && (
        <>
          {/* {toggleGrowthChartSnackbarContext.state && (
            <div
              className={`growth-chart-snackbar-btn ${growthChartSnackbarContext.state.classes}`}
              onClick={toggleSnackbarHandler}
            ></div>
          )} */}
          <div
            className={`growth-chart-snackbar-btn ${growthChartSnackbarContext.state.classes}`}
            onClick={toggleSnackbarHandler}
          ></div>

          {/* <div
            className={`growth-chart confidence-message alert alert-shadow alert-fade-in icon icon-paw ${
              growthChartSnackbarContext.state.classes
            } ${
              toggleConfidenceMessageSnackbarContext.state && "visually-hidden"
            }`}
            role="alert"
          > */}
          <div
            className={`growth-chart confidence-message alert alert-shadow alert-fade-in icon icon-paw ${
              growthChartSnackbarContext.state.classes
            } ${toggleSnackbar && "visually-hidden"}`}
            role="alert"
          >
            {growthChartSnackbarContext.state.confidenceMessage}
            <button
              type="button"
              className="btn-close d-lg-none"
              aria-label="Close"
              onClick={toggleSnackbarHandler}
            ></button>
          </div>
        </>
      )}
    </>
  )
}

export default ConfidenceMessageSnackbar
