import React, { useEffect, useContext } from "react"

import { SnackbarContext } from "../app"

function Snackbar() {
  const snackbarContext = useContext(SnackbarContext)

  useEffect(() => {
    const interval = setInterval(() => {
      if (snackbarContext.state.content !== "") {
        snackbarContext.setState({
          classes: "",
          content: "",
        })
      }
    }, 6000)
    return () => {
      clearInterval(interval)
    }
  }, [snackbarContext])

  return (
    <>
      {snackbarContext.state.content !== "" && (
        <div
          className={`alert alert-shadow alert-fade-in icon ${snackbarContext.state.classes}`}
          role="alert"
        >
          {snackbarContext.state.content}
        </div>
      )}
    </>
  )
}

export default Snackbar
