import React from "react"
import ExportPDF from "./export-pdf"

function ActionOptions({
  setShowActionOptions,
  setShowModalWeight,
  setShowAddNeutering,
  setShowModalSocialshare,
}) {
  return (
    <>
      <div className="modal-dialog-window">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close ms-auto d-lg-none"
              onClick={() => {
                setShowActionOptions(false)
              }}
            ></button>
          </div>
          <div className="modal-body">
            <div className="d-grid gap">
              <div
                className="d-inline-flex justify-content-center align-items-center btn-text-icon"
                onClick={() => {
                  setShowModalSocialshare(true)
                }}
              >
                <figure className="flex-shrink-0 mb-0">
                  <img
                    className="img-fluid"
                    src="../../icons/icon-share.png"
                    alt=""
                  />
                </figure>
                <span className="d-inline-block btn-text">Share Chart</span>
              </div>
              <ExportPDF setShowActionOptions={setShowActionOptions} />
              <button
                type="button"
                className="btn btn-outline-mars-blue"
                onClick={() => {
                  setShowAddNeutering(true)
                  setShowActionOptions(false)
                }}
              >
                Update neuter status
              </button>
              <button
                type="button"
                className="btn btn-mars-blue"
                onClick={() => {
                  setShowModalWeight(true)
                  setShowActionOptions(false)
                }}
              >
                Add new weight
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ActionOptions
