import React, { useState, useEffect, useContext } from "react"
import Modal from "react-bootstrap/Modal"
import CloseButton from "react-bootstrap/CloseButton"
import moment from "moment"
import axios from "axios"
import { isEqual } from "lodash"


import {
  LoadingContext,
  IsLoginContext,
  FetchDataContext,
  ModalContext,
  LoginProfileDataContext,
  SnackbarContext,
  TabRenderContext,
  AddedPetIdContext,
} from "../app"

import { UndoCloseContext } from "../templates/homepage/homepage"

import AddMyPet from "../forms/form-signup/form-add-my-pet"
import DialogueUnsavedChanges from "./helpers/dialogue-unsaved-changes"

function ModalAddPuppy(props) {
  const loadingContext = useContext(LoadingContext)
  const isLoginContext = useContext(IsLoginContext)
  const fetchDataContext = useContext(FetchDataContext)
  const modalContext = useContext(ModalContext)
  const loginProfileDataContext = useContext(LoginProfileDataContext)
  const snackbarContext = useContext(SnackbarContext)

  const tabRenderContext = useContext(TabRenderContext)
  const addedPetIdContext = useContext(AddedPetIdContext)
  const undoCloseContext = useContext(UndoCloseContext)

  const initialValues = {
    petName: "",
    birthDate: "",
    knowPetsBreed: "",
    petsBreed: "",
    mixedBreed: false,
    petsAdultWeight: "",
    petsGender: "",
    weightUnitPet: loginProfileDataContext.state.owner.weight_unit,
    species: "dog",
  }

  const [data, setData] = useState(initialValues)
  const [unSavedData, setUnSavedData] = useState(false)
  // const [addBtnDisabled, setAddBtnDisabled] = useState(false)
  // const [dobError, setDobError] = useState(false)
  // const [dobYear, setDobYear] = (undefined)

  // console.log(data?.birthDate?.toString().split(" ")[3])

  const isEqualData = () => {
    if (isEqual(initialValues, data)) {
      props.onHide()
    } else {
      setUnSavedData(true)
    }
  }

  useEffect(() => {
    setData(initialValues)
  }, [loginProfileDataContext])

  const makeAddPuppyRequest = (formData, dobRef) => {
    loadingContext.setLoading(true)

    const dataToSend = {
      pet_name: formData.petName,
      dob: moment(formData.birthDate).format("YYYY-MM-DD"),
      do_you_know_your_pets_breed: formData.knowPetsBreed,
      // breed: formData.mixedBreed === true ? null : formData.petsBreed,
      breed:
        formData.species === "cat" && formData.mixedBreed
          ? null
          : formData.petsBreed,
      mixed_breed: formData.mixedBreed === true ? "yes" : null,
      breed_size: formData.petsAdultWeight,
      sex: formData.petsGender,
      neuter_date: null,
      species: formData.species, //cat or dog allowed
    }
    var config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/add-pet?_queryFilter1=mail eq ${loginProfileDataContext.state.owner.email}&_queryFilter2=_id eq ${loginProfileDataContext.state.owner._id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)
        setData(initialValues)
        if (response.data._id) {
          fetchDataContext()
          addedPetIdContext.setState(response.data.petID)
          snackbarContext.setState({
            classes: "alert-success icon-success",
            content: "Pet's profile was created successfully.",
          })
          modalContext.dispatch("hideAddPuppyModal")
        } 
        // else if (
        //   response.data.error === true &&
        //   response.data.message.indexOf("dob") !== -1
        // ) {
        //   let message = response.data.message.split("'")
        //   setDobError(message[3])
        //   dobRef.current.focus()
        // } 
        else if (
          response.data.getIdTokenInfo_res.error &&
          response.data.getIdTokenInfo_res.error_description
        ) {
          isLoginContext.setIsLogin(false)
          modalContext.dispatch("hideAddPuppyModal")
        }
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
      })
  }
//  useEffect(() => {
//    if (
//      data &&
//      (data?.petName === "" ||
//        data?.birthDate === "" ||
//        data?.birthDate === null ||
//        data?.petsBreed === "" ||
//        data?.species === "" ||
//        data?.petsAdultWeight === "" ||
//        data?.petsGender === "") ||
//       Number(data?.birthDate?.toString().split(" ")[3]) < 1990
//       // formattedDate.length < 10
//    ) {
//      setAddBtnDisabled(true);
//    } else {
//      setAddBtnDisabled(false);
//    }
//  }, [data]);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        centered
        animation
        scrollable
        onEntered={() => {
          tabRenderContext.setState(false)
          undoCloseContext.setState(false)
        }}
        onExited={() => {
          // setData(initialValues)
          setUnSavedData(false)
        }}
        className={`${unSavedData && "remove-user-profile bg-image-none"}`}
        backdrop="static"
      >
        {!unSavedData ? (
          <>
            <Modal.Header>
              <Modal.Title as="h6" className="text-mars-blue">
                Add New Pet
              </Modal.Title>
              <CloseButton
                onClick={() => {
                  isEqualData()
                }}
              />
            </Modal.Header>
            <Modal.Body>
              <AddMyPet
                data={data}
                setData={setData}
                addNewPuppy={true}
                makeAddPuppyRequest={makeAddPuppyRequest}
                // dobError={dobError}
                // setDobError={setDobError}
                resetValues={initialValues}
              />
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-outline-mars-blue"
                onClick={() => {
                  isEqualData()
                }}
              >
                Close
              </button>

              <button type="submit" className="btn btn-mars-blue" form="myform">
                Add pet
              </button>
            </Modal.Footer>
          </>
        ) : (
          <DialogueUnsavedChanges
            setUnSavedData={setUnSavedData}
            title={"You have unsaved changes in pet’s profile."}
            body={
              "If you leave a pet's profile, all made changes will be lost."
            }
            backBtnText={"Back To Profile"}
            cancelBtnText={"Leave Profile"}
            hideModal={"hideAddPuppyModal"}
          />
        )}
      </Modal>
    </>
  )
}

export default ModalAddPuppy
