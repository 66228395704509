import React, { useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"

import { Navigation, Pagination } from "swiper"

import SignUp from "../signup/signup"

function WelcomeCarouselMobile(props) {
  const [swiper, setSwiper] = useState()
  const [skipShow, setSkipShow] = useState(true)
  const skipHandler = () => {
    swiper.slideTo(swiper.slides.length - 1, 500, false)
  }

  return (
    <>
      <div className="welcome-screen__chart">
        <div className="welcome-screen_carousel">
          <Swiper
            pagination={{
              el: ".swiper-pagination",
              type: "bullets",
            }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            modules={[Navigation, Pagination]}
            className="welcome-screen-swiper"
            onSwiper={swiper => {
              setSwiper(swiper)
            }}
            onSlideChange={() => {
              if (swiper.activeIndex === swiper.slides.length - 1) {
                setSkipShow(false)
              } else {
                setSkipShow(true)
              }
            }}
          >
            {/* <SwiperSlide>
              <p className="subtitle-1">See the chart for different puppies</p>
              <figure className="text-center mb-0">
                <img
                  className="img-fluid"
                  src="../../welcome-carousel/mobile/slide1.png"
                  alt=""
                />
              </figure>
            </SwiperSlide>
            <SwiperSlide>
              <p className="subtitle-1">Add Actions</p>
              <figure className="text-center mb-0">
                <img
                  className="img-fluid"
                  src="../../welcome-carousel/mobile/slide2.png"
                  alt=""
                />
              </figure>
            </SwiperSlide> */}
            {/* <SwiperSlide>
              <p className="subtitle-1">Add New Puppy</p>
              <figure className="text-center mb-0">
                <img
                  className="img-fluid"
                  src="../../welcome-carousel/mobile/add-pet.gif"
                  alt=""
                />
              </figure>
            </SwiperSlide> */}
            <SwiperSlide>
              {/* <p className="subtitle-1">Add Weights</p> */}
              <figure className="text-center mb-0">
                <img
                  className="img-fluid"
                  src="../../welcome-carousel/mobile/add-weights-one.gif"
                  alt=""
                />
              </figure>
            </SwiperSlide>
            <SwiperSlide>
              {/* <p className="subtitle-1">Add Weights</p> */}
              <figure className="text-center mb-0">
                <img
                  className="img-fluid"
                  src="../../welcome-carousel/mobile/add-weights-two.gif"
                  alt=""
                />
              </figure>
            </SwiperSlide>
            <SwiperSlide>
              <SignUp />
            </SwiperSlide>
          </Swiper>
          <div className="swiper-pagination"></div>

          <div className="swiper-button-prev"></div>
          <div className="swiper-button-next"></div>
        </div>
        {skipShow && (
          <div
            className="d-inline-block btn-text btn-skip px-2 py-1"
            onClick={skipHandler}
          >
            Skip
          </div>
        )}
      </div>
    </>
  )
}

export default WelcomeCarouselMobile
