import React, { useState, useContext, useEffect } from "react"
import Modal from "react-bootstrap/Modal"
import axios from "axios"

import UserProfile from "../forms/form-signup/form-user-profile"
import OTPBox from "../forms/otp-box/otp-box"

import {
  LoadingContext,
  ModalContext,
  ResetPasswordContext,
  LoginProfileDataContext,
  SnackbarContext,
  FetchDataContext,
  IsLoginContext,
  TabRenderContext,
  OTPRequestContext,
} from "../app"
import { initial } from "lodash"

function ModalUpdateUserProfile(props) {
  const isLoginContext = useContext(IsLoginContext)
  const loadingContext = useContext(LoadingContext)
  const resetPasswordContext = useContext(ResetPasswordContext)
  const loginProfileDataContext = useContext(LoginProfileDataContext)
  const modalContext = useContext(ModalContext)
  const snackbarContext = useContext(SnackbarContext)
  const fetchDataContext = useContext(FetchDataContext)
  const otpRequestContext = useContext(OTPRequestContext)

  const tabRenderContext = useContext(TabRenderContext)

  const initialValues = {
    firstName: loginProfileDataContext.state.owner.first_name,
    lastName: loginProfileDataContext.state.owner.last_name,
    emailAddress: loginProfileDataContext.state.owner.email,
    weightUnitProfile: loginProfileDataContext.state.owner.weight_unit,
    userPassword: "PasswordField",
  }

  const [data, setData] = useState(initialValues)
  const [updateUserProfile, setUpdateUserProfile] = useState(true)

  const [passwordNotChanged, setPasswordNotChanged] = useState(false)
  const [forgotPasswordResponseData, setForgotPasswordResponseData] = useState(
    {}
  )
  const [verifyOTPResponse, setVerifyOTPResponse] = useState({})
  const [updateOwnerResponse, setUpdateOwnerResponse] = useState({})
  const [verifyOldOTPResponse, setVerifyOldOTPResponse] = useState({})
  const [oldOtp, setOldOtp] = useState(false)
  const [newOtp, setNewOtp] = useState(false)
  const [OTPError, setOTPError] = useState("")

  const removeTokenHandler = () => {
    localStorage.removeItem("token")
  }

  const makeForgotPasswordRequest = emailAddress => {
    loadingContext.setLoading(true)
    setOTPError("")
    const dataToSend = {
      email: emailAddress,
    }

    var config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/forgotpassword`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)
        setUpdateUserProfile(false)
        setForgotPasswordResponseData(response.data)
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
      })
  }

  const makeVerifyOtpRequest = (
    OTPValue,
    setOTPMatched,
    setOTPNotMatched,
    setOTPResendEnable,
    setOTPTimerStart,
    setOTPTimerDuration
  ) => {
    loadingContext.setLoading(true)
    const dataToSend = {
      verifyOTPAuthID: forgotPasswordResponseData.verifyOTPAuthID,
      verifyOTPCookie: forgotPasswordResponseData.verifyOTPCookie,
      otp: OTPValue,
    }

    var config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/verify-otp`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)
        if (response.data.message === "Login failure") {
          setOTPNotMatched(true)
        } else if (
          response.data.message ===
          "Warning: You will be locked out after 2 more failure(s)."
        ) {
          setOTPError("You will be locked out after 2 more failure(s).")
          setOTPNotMatched(true)
        } else if (
          response.data.message ===
          "Warning: You will be locked out after 1 more failure(s)."
        ) {
          setOTPError("You will be locked out after 1 more failure(s).")
          setOTPNotMatched(true)
        } else if (response.data.message === "User Locked Out.") {
          setOTPError(
            "Your account is temporarily locked. please retry in 5 minutes."
          )
          setOTPNotMatched(false)
          setOTPResendEnable(false)
          setOTPTimerStart(false)
          setOTPTimerDuration(30)
          removeTokenHandler()
        } else if (
          response.data.callbacks[0].type === "ValidatedCreatePasswordCallback"
        ) {
          setOTPError("")
          setOTPMatched(true)
          setOTPNotMatched(false)
          setOTPResendEnable(false)
          setOTPTimerStart(false)
          setOTPTimerDuration(30)
          setVerifyOTPResponse(response.data)
        } else {
          setOTPNotMatched(true)
        }
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
        setOTPNotMatched(true)
      })
  }

  const makeResetPasswordRequest = formData => {
    loadingContext.setLoading(true)
    const dataToSend = {
      resetPasswordAuthID: verifyOTPResponse.resetPasswordAuthID,
      resetPasswordCookie: verifyOTPResponse.resetPasswordCookie,
      password: formData.newPassword,
    }

    var config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/reset-password`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)
        if (response.data.failedPolicies) {
          otpRequestContext.dispatch({
            type: "PASSWORD_FAILED",
            passwordErrorText: response.data.failedPolicies.password.message,
          })
          setPasswordNotChanged(true)
        } else if (response.data.tokenId) {
          otpRequestContext.dispatch({
            type: "OTP_SENT_SUCCESS",
            authId: response.data.authId,
            ownerEnterCookie: response.data.owner_enter_cookie,
          })
          setPasswordNotChanged(false)
          modalContext.dispatch("hideUpdateUserProfileModal")
          snackbarContext.setState({
            classes: "alert-success icon-success",
            content: "Your password was changed successfully.",
          })
        } else {
          setPasswordNotChanged(true)
        }
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
      })
  }

  // console.log(data);
  useEffect(() => {
    setData({
      ...data,
      firstName: loginProfileDataContext.state.owner.first_name,
      lastName: loginProfileDataContext.state.owner.last_name,
      emailAddress: loginProfileDataContext.state.owner.email,
      weightUnitProfile: loginProfileDataContext.state.owner.weight_unit,
    });
  }, [loginProfileDataContext]);

  const makeUpdateOwnerRequest = formData => {
    // console.log(formData)
    loadingContext.setLoading(true)
    // setData({ ...data, ...formData })
    let filteredData = {}

    if (loginProfileDataContext.state.owner.first_name !== formData.firstName) {
      filteredData.first_name = formData.firstName
    }
    if (loginProfileDataContext.state.owner.last_name !== formData.lastName) {
      filteredData.last_name = formData.lastName
    }
    if (loginProfileDataContext.state.owner.email !== formData.emailAddress) {
      filteredData.email = formData.emailAddress
    }
    if (
      loginProfileDataContext.state.owner.weight_unit !==
      formData.weightUnitProfile
    ) {
      filteredData.weight_unit = formData.weightUnitProfile
    }

    const dataToSend = filteredData

    var config = {
      method: "patch",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/update-owner?_queryFilter1=mail eq ${loginProfileDataContext.state.owner.email}&_queryFilter2=_id eq ${loginProfileDataContext.state.owner._id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)

        if (response.data.owner_updated) {
          modalContext.dispatch("hideUpdateUserProfileModal")
          setTimeout(() => {
            fetchDataContext()
          }, 1000)

          snackbarContext.setState({
            classes: "alert-success icon-success",
            content: "User's profile was updated successfully",
          })
        } else if (response.data.enterOldEmail_suc.authId) {
          setUpdateOwnerResponse(response.data)
          setUpdateUserProfile(false)
          setOldOtp(true)
          setNewOtp(false)
        }
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
        modalContext.dispatch("hideUpdateUserProfileModal")
      })
  }

  const makeVerifyOldOtpRequest = (
    OTPValue,
    setOTPValue,
    setOTPMatched,
    setOTPNotMatched,
    setOTPResendEnable,
    setOTPTimerStart,
    setOTPTimerDuration
  ) => {
    loadingContext.setLoading(true)
    const dataToSend = {
      enterOldEmail_suc: {
        authId: updateOwnerResponse.enterOldEmail_suc.authId,
        enterOldEmail_cookie:
          updateOwnerResponse.enterOldEmail_suc.enterOldEmail_cookie,
      },
      otp: OTPValue,
      req_fields: updateOwnerResponse.req_fields,
      _id: updateOwnerResponse._id,
    }

    var config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/verify-old-email-otp`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)

        if (response.data.message === "Invalid OTP.") {
          setOTPNotMatched(true)
        } else if (
          response.data.message ===
          "Warning: You will be locked out after 2 more failure(s)."
        ) {
          setOTPError("You will be locked out after 2 more failure(s).")
          setOTPNotMatched(true)
        } else if (
          response.data.message ===
          "Warning: You will be locked out after 1 more failure(s)."
        ) {
          setOTPError("You will be locked out after 1 more failure(s).")
          setOTPNotMatched(true)
        } else if (response.data.message === "User Locked Out.") {
          setOTPError(
            "Your account is temporarily locked. please retry in 5 minutes."
          )
          setOTPNotMatched(false)
          setOTPResendEnable(false)
          setOTPTimerStart(false)
          setOTPTimerDuration(30)
          removeTokenHandler()
        } else if (response.data.enterNewEmail_suc) {
          setOTPError("")
          setOTPNotMatched(false)
          setOTPValue("")
          setOTPTimerStart(true)
          setOTPResendEnable(false)
          setOldOtp(false)
          setNewOtp(true)
          setVerifyOldOTPResponse(response.data)
        } else {
          setOTPNotMatched(true)
        }
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
        setOTPNotMatched(true)
      })
  }

  const makeVerifyNewOtpRequest = (
    OTPValue,
    setOTPValue,
    setOTPMatched,
    setOTPNotMatched,
    setOTPResendEnable,
    setOTPTimerStart,
    setOTPTimerDuration
  ) => {
    loadingContext.setLoading(true)
    const dataToSend = {
      enterNewEmail_suc: {
        authId: verifyOldOTPResponse.enterNewEmail_suc.authId,
        enterNewEmail_cookie:
          verifyOldOTPResponse.enterNewEmail_suc.enterNewEmail_cookie,
      },
      otp: OTPValue,
      req_fields: verifyOldOTPResponse.req_fields,
      _id: verifyOldOTPResponse._id,
    }

    var config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/verify-new-email-otp`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)

        if (response.data.message === "Invalid OTP.") {
          setOTPNotMatched(true)
        } else if (response.data.owner_updated) {
          modalContext.dispatch("hideUpdateUserProfileModal")
          isLoginContext.setIsLogin(false)

          snackbarContext.setState({
            classes: "alert-success icon-success",
            content: "Your email address was changed successfully.",
          })
          setOTPValue("")
        } else {
          setOTPNotMatched(true)
        }
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
        setOTPNotMatched(true)
      })
  }

  const makeResendNewOtpRequest = (
    OTPValue,
    setOTPValue,
    setOTPMatched,
    setOTPNotMatched
  ) => {
    loadingContext.setLoading(true)
    const dataToSend = {
      verifyOldEmailOtp_suc: {
        authId: verifyOldOTPResponse.verifyOldEmailOtp_suc.authId,
        verifyOldEmailOtp_cookie:
          verifyOldOTPResponse.verifyOldEmailOtp_suc.verifyOldEmailOtp_cookie,
      },
      req_fields: verifyOldOTPResponse.req_fields,
      _id: verifyOldOTPResponse._id,
    }

    var config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/resend-otp-new-email`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)
        if (response.data.message === "Invalid OTP.") {
          setOTPNotMatched(true)
        } else if (response.data.enterNewEmail_suc) {
          setVerifyOldOTPResponse({
            ...verifyOldOTPResponse,
            enterNewEmail_suc: {
              authId: response.data.enterNewEmail_suc.authId,
              enterNewEmail_cookie:
                response.data.enterNewEmail_suc.enterNewEmail_cookie,
            },
            req_fields: response.data.req_fields,
            _id: response.data._id,
          })
          setOTPValue("")
          setOldOtp(false)
          setNewOtp(true)

          // setOTPNotMatched(false)
        }
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
        setOTPNotMatched(true)
      })
  }

  return (
    <>
      <Modal
        {...props}
        size="lg"
        centered
        animation
        scrollable
        onEntered={() => {
          resetPasswordContext.setState(true)
          tabRenderContext.setState(false)
        }}
        onExited={() => {
          setUpdateUserProfile(true)
          setData(initialValues)
          resetPasswordContext.setState(false)
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6" className="text-mars-blue">
            {updateUserProfile || oldOtp || newOtp
              ? "User profile"
              : "Reset password"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {updateUserProfile ? (
            <UserProfile
              updateUserProfile={updateUserProfile}
              setUpdateUserProfile={setUpdateUserProfile}
              data={data}
              setData={setData}
              userInitialValues={initialValues}
              makeForgotPasswordRequest={makeForgotPasswordRequest}
              makeUpdateOwnerRequest={makeUpdateOwnerRequest}
            />
          ) : (
            <OTPBox
              data={data}
              emailAddress={
                oldOtp
                  ? loginProfileDataContext.state.owner.email
                  : data.emailAddress
              }
              setIsLogin={props.setIsLogin}
              forgotPassword={true}
              title={
                oldOtp
                  ? "For changing the email address, we need to verify the old email."
                  : newOtp
                  ? "For changing the email address, we need to verify the new email."
                  : "To complete password reset process, we need to verify your email."
              }
              makeForgotPasswordRequest={makeForgotPasswordRequest}
              forgotPasswordResponseData={forgotPasswordResponseData}
              verifyOTPResponse={verifyOTPResponse}
              setVerifyOTPResponse={setVerifyOTPResponse}
              makeResetPasswordRequest={makeResetPasswordRequest}
              passwordNotChanged={passwordNotChanged}
              setPasswordNotChanged={setPasswordNotChanged}
              makeVerifyOtpRequest={makeVerifyOtpRequest}
              oldOtp={oldOtp}
              newOtp={newOtp}
              OTPError={OTPError}
              setOTPError={setOTPError}
              makeUpdateOwnerRequest={makeUpdateOwnerRequest}
              makeVerifyOldOtpRequest={makeVerifyOldOtpRequest}
              makeVerifyNewOtpRequest={makeVerifyNewOtpRequest}
              makeResendNewOtpRequest={makeResendNewOtpRequest}
            />
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          {updateUserProfile ? (
            <>
              <button
                type="button"
                className="btn btn-outline-mars-blue"
                onClick={props.onHide}
              >
                Close
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-outline-mars-blue"
                onClick={() => setUpdateUserProfile(true)}
              >
                Back
              </button>
            </>
          )}
          <button type="submit" className="btn btn-mars-blue" form="myform">
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalUpdateUserProfile
