import React from "react"

function WelcomeHeader() {
  return (
    <>
      <header className="d-none d-lg-flex justify-content-between align-items-center chart-container__header">
        <h5 className="text-mars-red mb-0">
          Help your pet have the best start in life by tracking their weight as
          they grow.
        </h5>
        <figure className="mb-0">
          <img
            className="img-fluid"
            src="../../better-world-pets-logo.png"
            alt="A better world for pets"
          />
        </figure>
      </header>
    </>
  )
}

export default WelcomeHeader
