import React, { useState } from "react"
import { FormCheck } from "react-bootstrap"

function WeightToggle({ weightUnitLocal, setWeightUnitLocal }) {
  return (
    <>
      <div className="btn-group">
        <FormCheck.Input
          as="input"
          type="radio"
          id="kg"
          className="btn-check"
          name="weight"
          value="kg"
          onChange={e => setWeightUnitLocal(e.target.value)}
          checked={weightUnitLocal === "kg"}
        />
        <label className="btn" htmlFor="kg">
          kg
        </label>

        <FormCheck.Input
          as="input"
          type="radio"
          id="lbs"
          className="btn-check"
          name="weight"
          value="lbs"
          onChange={e => setWeightUnitLocal(e.target.value)}
          checked={weightUnitLocal === "lbs"}
        />
        <label className="btn" htmlFor="lbs">
          lbs
        </label>
      </div>
    </>
  )
}

export default WeightToggle
