import React from "react"

function NoPetsFound({ searchPets }) {

  return (
    <>
      <div className="no-pets-found d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start align-items-center">
        <figure className="mb-0">
          {searchPets.petSpecies === "dog" && (
            <img className="img-fluid" src="/silhouette-dog-1-0.svg" alt="" />
          )}

          {searchPets.petSpecies === "cat" && (
            <img className="img-fluid" src="/silhouette-cat-1.svg" alt="" />
          )}

          {searchPets.petSpecies === "" && (
            <img className="img-fluid" src="/silhouette-dog-1.svg" alt="" />
          )}
        </figure>
        <h6 className="text-mars-blue mb-0">
          Oh no! No{" "}
          {searchPets.petSpecies === "dog"
            ? "puppies"
            : searchPets.petSpecies === "cat"
            ? "kittens"
            : "pets"}{" "}
          for "{searchPets.searchTerm}". Try another search!
        </h6>
      </div>
    </>
  )
}

export default NoPetsFound
