import React from "react"

const SearchField = ({ searchPets, setSearchPets }) => {
  return (
    <>
      <div className="search-container flex-grow-1">
        <div className="input-group form-input-hldr mb-0 ">
          <input
            type="text"
            className="form-control"
            placeholder={
              searchPets.petSpecies === "dog"
                ? "All Puppy"
                : searchPets.petSpecies === "cat"
                ? "All Kitten"
                : "Search for a pet"
            }
            onChange={event => {
              setSearchPets({ ...searchPets, searchTerm: event.target.value })
            }}
            value={searchPets.searchTerm}
          />

          <span
            className="input-group-text"
            onClick={() => setSearchPets({ petSpecies: "", searchTerm: "" })}
          >
            <figure className="mb-0">
              <img
                className="img-fluid"
                src={`/icons/icon-${
                  // searchPets.petSpecies === "" || searchPets.searchTerm === ""
                  //   ? "search"
                  //   : "search-close"
                  !searchPets.petSpecies
                    ? !searchPets.searchTerm
                      ? "search"
                      : "search-close"
                    : "search-close"
                }.svg`}
              />
            </figure>
          </span>
          <div className="search-filter-content">
            <div className="d-flex justify-content-between">
              <div>
                <button
                  className={searchPets.petSpecies === "dog" && "active"}
                  onClick={() => {
                    if (searchPets.petSpecies === "dog") {
                      setSearchPets({ ...searchPets, petSpecies: "" })
                    } else {
                      setSearchPets({ ...searchPets, petSpecies: "dog" })
                    }
                  }}
                >
                  Puppy
                </button>
                <button
                  className={searchPets.petSpecies === "cat" && "active"}
                  onClick={() => {
                    if (searchPets.petSpecies === "cat") {
                      setSearchPets({ ...searchPets, petSpecies: "" })
                    } else {
                      setSearchPets({ ...searchPets, petSpecies: "cat" })
                    }
                  }}
                >
                  Kitten
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SearchField
