import React, { useEffect, useContext } from "react"

import { Tabs, Tab } from "react-bootstrap"

import {
  SnackbarContext,
  GrowthChartSnackbarContext,
  TabInitialKeyContext,
} from "../../app"
import {
  CurrentPetDataContext,
  TabActiveKeyContext,
} from "../homepage/homepage"

import SearchField from "../../search-field/search-field"
import ProfileDropdown from "../../profile-dropdown/profile-dropdown"
import PuppyListCarousel from "../../carousels/puppy-list-carousel"
import DataVisualization from "../../data-visualization/data-visualization"
import Snackbar from "../../snackbar/snackbar"
import ConfidenceMessageSnackbar from "../../snackbar/confidenceMessageSnackbar"
import GrowthGuidanceSnackbar from "../../snackbar/growthGuidanceSnackbar"

function HomepageMobile({ searchPets, setSearchPets, setModalManageProfile }) {
  const snackbarContext = useContext(SnackbarContext)
  const growthChartSnackbarContext = useContext(GrowthChartSnackbarContext)
  const currentPetDataContext = useContext(CurrentPetDataContext)
  const tabInitialKeyContext = useContext(TabInitialKeyContext)
  const tabActiveKeyContext = useContext(TabActiveKeyContext)
  // const [key, setKey] = useState("myPets")

  useEffect(() => {
    tabActiveKeyContext.setState("myPets")
  }, [])

  return (
    <>
      <section className="chart-container chart-container__bg position-relative">
        <header className="d-flex justify-content-between align-items-center chart-container__login-header">
          <ProfileDropdown setModalManageProfile={setModalManageProfile} />
        </header>

        {snackbarContext.state.content !== "" && (
          <div className="homepage-mobile-snackbar">
            <Snackbar />
          </div>
        )}
        {snackbarContext.state.content === "" &&
          growthChartSnackbarContext.state.confidenceMessage !== "" &&
          tabInitialKeyContext.state === "growthChart" && (
            <>
              <div className="homepage-mobile-snackbar">
                <ConfidenceMessageSnackbar />
              </div>
              <div className="growth-guidance-snackbar">
                <GrowthGuidanceSnackbar />
              </div>
            </>
          )}

        <Tabs
          activeKey={tabInitialKeyContext.state}
          // onSelect={k => setKey(k)}
          className="nav-pills"
          fill
          onSelect={key => {
            tabInitialKeyContext.setState(key)
            tabActiveKeyContext.setState(key)

            growthChartSnackbarContext.setState({
              classes: "alert-light icon-info",
              confidenceMessage: `${
                currentPetDataContext.state.weights.weights.length !== 0
                  ? currentPetDataContext.state.weights.recommendation
                      .confidenceMessage.text
                  : ""
              }`,
              growthGuidance: `${
                currentPetDataContext.state.weights.weights.length !== 0
                  ? currentPetDataContext.state.weights.recommendation
                      .growthGuidance.text
                  : ""
              }`,
            })
          }}
        >
          <Tab eventKey="myPets" title="My Pets">
            <p className="body-2 text-mars-blue">
              Keep your pet's growth on track by regularly monitoring their
              weight.
            </p>
            <SearchField
              searchPets={searchPets}
              setSearchPets={setSearchPets}
            />
            <PuppyListCarousel searchPets={searchPets} />
          </Tab>
          <Tab eventKey="growthChart" title="Growth Chart">
            <div id="data-visual">
              <h6 className="text-center text-mars-blue mb-0 mt-3">
                {currentPetDataContext.state.pet_name}
              </h6>
              <DataVisualization />
            </div>
          </Tab>
        </Tabs>
      </section>
    </>
  )
}

export default HomepageMobile
