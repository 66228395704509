import React, { useContext } from "react"

import html2canvas from "html2canvas"
import { jsPDF } from "jspdf"

import { CurrentPetDataContext } from "../../templates/homepage/homepage"

const ExportPDF = ({ setShowActionOptions }) => {
  const currentPetDataContext = useContext(CurrentPetDataContext)
  // console.log(currentPetDataContext.state)
  const exportPDF = () => {
    document.body.classList.add("export-pdf")

    const input = document.getElementById("iframe-height")
    html2canvas(input, {
      loading: true,
      letterRendering: 1,
      useCORS: true,
    })
      .then(canvas => {
        const imgWidth = 800
        const imgHeight = (canvas.height * imgWidth) / canvas.width
        const imgData = canvas.toDataURL("img/png")
        console.log(imgData)

        const pdf = new jsPDF("l", "px", [imgWidth, imgHeight])
        pdf.addImage(imgData, "PNG", 20, 20, imgWidth - 40, imgHeight - 40)
        pdf.save(`${currentPetDataContext.state.pet_name}_report.pdf`)
      })
      .then(() => {
        setShowActionOptions(false)
        document.body.classList.remove("export-pdf")
      })
  }

  return (
    <>
      <div className="d-inline-flex justify-content-center align-items-center btn-text-icon">
        <figure className="flex-shrink-0 mb-0">
          <img className="img-fluid" src="../../icons/icon-pdf.png" alt="" />
        </figure>
        <span className="d-inline-block btn-text" onClick={() => exportPDF()}>
          Export as PDF
        </span>
      </div>
    </>
  )
}

export default ExportPDF
